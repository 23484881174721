/* eslint-disable react/no-array-index-key */
/* eslint-disable radix */
/* eslint-disable camelcase */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Button,
  Flex,
  Radio as ChakraRadio,
  RadioGroup,
  SimpleGrid,
  Stack,
  Text,
  Textarea,
  useBreakpointValue,
  useToast
} from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'

import format from 'date-fns/format'
import lodash from 'lodash'
import { useTranslation } from 'react-i18next'
import { Input } from '../../components/Form/Input'
import { CardContainer } from '../../components/molecules/CardContainer'
import { WebcamCapture } from '../../components/Webcam'
import { ImageComponent } from '../../components/Webcam/ImageComponent'
import Dropzone from './Dropzone'

import { ArrowBack } from '../../components/atoms/arrowBack'
import { InputPhone2 } from '../../components/Form/InputPhone2'
import { Select } from '../../components/Form/Select'
import { SelectComponent } from '../../components/Form/SelectComponent'
import { SelectComponentAsync } from '../../components/Form/SelectComponentAsync'
import {
  MasksTypes,
  TextFieldControlled
} from '../../components/Form/TextFieldControlled'
import { Header } from '../../components/Header'
import { ModalWebcam } from '../../components/Modal/modalWebcam'
import { Sidebar } from '../../components/Sidebar'
import { useAuth } from '../../hooks/auth'
import { useDocument } from '../../hooks/useDocument'
import { useModal } from '../../hooks/useModal'
import { apiAuth } from '../../services/apiAuth'
import { mask } from '../../utils/mask'
import { useAddress } from '../../utils/services/useAddress'
import { useHealthInsurance } from '../Settings/Covenants/useHealthInsurance'
import { useAttendance as useOrigin } from '../Settings/Origins/service'
import { useAttendance as useTag } from '../Settings/Tags/service'
import { useCustomers } from './logic'
import { usePatients } from './service'

export type FormClientData = {
  name: string
  nascimento: string
  idade: string
}

type ContactsData = {
  contact: string
  ddi_country: string
  id: string
  is_main: boolean
  description: string
}

type EmailsData = {
  id: string
  email: string
  description: string
}

// form values
type FormData = {
  complement: string
  numero: string
  occupation: string
  is_active: string
  alerts: string
  observation: string
  state: string
  sex: string
}

type DocumentSelect = {
  label: string
  value: number
  regex: string
}

type ClinicData = {
  patient_birth_date: boolean
  patient_document: boolean
  patient_origin: boolean
  hours_attendance_start: null | number
  hours_attendance_cancel: null | number
  hours_attendance_finish: null | number
  adding_itens_to_attendance: boolean
  documents: Documents[]
}

type Documents = {
  id: number
  name_ptbr: string
  name_en: string
  acronym: string
  index: Number
  regex: MasksTypes
}

type DocumentsOptions = Array<{
  label: string
  value: number
  regex: any
}>

export function CreateClients() {
  const [t] = useTranslation('pageClients')
  const toast = useToast()
  const { imgSrc } = useDocument()
  const { decodeToken, clinicId } = useAuth()
  const { clinic_language } = decodeToken
  const isWideVersion = useBreakpointValue({
    base: false,
    lg: true
  })
  const {
    calculateAge,
    healthInsurance,
    handleInputConvenio,
    handleSelectConvenio,
    addFieldsConvenio,
    removeFieldsConvenio,
    patientRelatives,
    addFieldsRelatives,
    removeFieldsRelatives,
    handleSelectedRelativesNames,
    handleSelectRelativesFamily,
    handleSelectedTags,
    handleSelectedOrigins,
    setPatientsTags,
    patientsTags,
    patientsOrigins
  } = useCustomers()
  const { LocaleApp } = useModal()
  const address = useAddress()
  const history = useHistory()
  const clientService = usePatients()
  const tagsUpdate = clientService.tagsServices
  const originsUpdate = clientService.originsServices
  const optionsFamily = [
    {
      value: 1,
      label: `${t('pageClients.PageNovoCliente.containerFour.seletedPar.pai')}`
    },
    {
      value: 2,
      label: `${t('pageClients.PageNovoCliente.containerFour.seletedPar.mae')}`
    },
    {
      value: 3,
      label: `${t('pageClients.PageNovoCliente.containerFour.seletedPar.irma')}`
    },
    {
      value: 4,
      label: `${t('pageClients.PageNovoCliente.containerFour.seletedPar.tio')}`
    },
    {
      value: 5,
      label: `${t(
        'pageClients.PageNovoCliente.containerFour.seletedPar.outros'
      )}`
    }
  ]
  const covenantsUpdate = clientService.covenantsServices
  const { state }: any = history.location
  // states
  const [localMethod, setLocal] = useState('')
  const [ageUser, setAgeUser] = useState('')
  const [getValuesSelectEdit, setGetValuesSelectEdit] = useState(false)

  // hooks
  const tags: any = useTag()
  const origins: any = useOrigin()
  const healthInsuranceAPI = useHealthInsurance()

  // Options Selects
  let tagArray: any = []
  tags.attendance.map((tag: any) => {
    tagArray = [...tagArray, { value: String(tag.id), label: tag.name }]
  })

  let origensArray: any = []
  origins.attendance.map((tag: any) => {
    origensArray = [...origensArray, { value: String(tag.id), label: tag.name }]
  })

  // Form
  const { register, handleSubmit, formState } = useForm<FormData>({})
  const { errors } = formState

  // states
  const [documents, setDocuments] = useState<DocumentsOptions>([])
  const [valueDocumentSelect, setValueDocumentSelect] =
    useState<DocumentSelect>()
  const [typeDocument, setTypeDocument] = useState<any>()
  const [numberDocument, setNumberDocument] = useState('')
  const [numbers, setNumbers] = useState<ContactsData[]>([
    {
      contact: '',
      ddi_country:
        decodeToken?.clinic?.clinic_contacts[0]?.ddi_country || '+55',
      id: '',
      is_main: true,
      description: ''
    }
  ])
  const [emails, setEmails] = useState<EmailsData[]>([
    {
      id: lodash.uniqueId(),
      email: '',
      description: ''
    }
  ])
  const [sex, setSex] = useState('')
  const [birth_date, setBirthDate] = useState('')
  const [name, setName] = useState('')
  const [socialName, setSocialName] = useState('')
  // states modal
  const [isOpen, setIsOpen] = useState(false)

  useEffect(() => {
    apiAuth.get<ClinicData>(`clinics/clinics/${clinicId}/`).then(({ data }) => {
      if (data?.documents) {
        const documentsFormat = data?.documents?.map((doc) => {
          if (clinic_language === 'en') {
            return {
              label: doc?.name_en,
              value: doc?.id,
              regex: doc?.regex
            }
          }

          if (clinic_language === 'es') {
            return {
              label: doc?.name_en,
              value: doc?.id,
              regex: doc?.regex
            }
          }

          return {
            label: doc?.name_ptbr,
            value: doc?.id,
            regex: doc?.regex
          }
        })

        setDocuments(documentsFormat)
      }
    })

    return () => {}
  }, [])

  // format tags, origens, numbers, emails
  function formatTagsServices() {
    if (patientsTags.length > 0) {
      const serviceTags = patientsTags.map((tag: any) => {
        return Number(tag.value)
      })

      return serviceTags
    }

    return []
  }

  function formatOriginsServices() {
    if (patientsOrigins.length > 0) {
      const serviceTags = patientsOrigins.map((origin: any) => {
        return Number(origin.value)
      })

      return serviceTags
    }

    return []
  }

  function formatNumbers() {
    if (numbers[0].contact === '') {
      return undefined
    }

    const telephones = numbers.map((item, index) => {
      return {
        ddi_country: item.ddi_country === '' ? null : item.ddi_country,
        number: item.contact === '' ? null : item.contact,
        index,
        description: item.description === '' ? null : item.description
      }
    })

    return telephones
  }

  function formatEmails() {
    if (emails[0].email === '') {
      return undefined
    }

    const emailsFormat = emails.map((email, index) => {
      return {
        email: email.email === '' ? null : email.email,
        index,
        description: email.description === '' ? null : email.description
      }
    })

    return emailsFormat
  }

  function formatHealthInsurance() {
    const healthInsuranceAllSelectReturn: any = healthInsurance.map((heal) => {
      if (heal.healthInsurance !== null) {
        return {
          health_insurance: {
            id: heal.healthInsurance?.value
          },
          number:
            heal.healthInsuranceNumber !== ''
              ? heal.healthInsuranceNumber
              : null
        }
      }

      return false
    })

    const health = healthInsuranceAllSelectReturn.filter(Boolean)

    return health
  }

  function formatPatientRelatives() {
    const patientRelativesAllSelectReturn = patientRelatives.map((patient) => {
      if (patient.patientRelatives !== null) {
        return {
          parent: {
            id: patient.patientRelatives.value
          },
          parent_degree: Number(patient.patientDegree || '5')
        }
      }

      return false
    })

    const parent = patientRelativesAllSelectReturn.filter(Boolean)

    return parent
  }

  // is required
  function isRequiredReturnForForm(obj: any | null | string) {
    let isRequired = false

    if (obj !== null) {
      isRequired = true
      return isRequired
    }

    if (obj === '') {
      isRequired = true
      return isRequired
    }

    return isRequired
  }

  // NUMBERS FUNCTIONS
  function handleAddNumbers() {
    setNumbers([
      ...numbers,
      {
        contact: '',
        ddi_country: '+55',
        id: '',
        is_main: true,
        description: ''
      }
    ])
  }

  function handleRemoveNumbers(index: number) {
    const values = [...numbers]
    values.splice(index, 1)
    setNumbers(values)
  }

  function handleChangeInputNumbers(
    index: number,
    e: React.ChangeEvent<HTMLInputElement>
  ) {
    const values = [...numbers]

    if (values[index].ddi_country === '+55') {
      values[index].contact = mask(e.target.value, '(99) 99999-9999')
    } else {
      values[index].contact = mask(e.target.value, '9999999999999999')
    }

    setNumbers(values)
  }

  function handlerChangeDescriptionContact(
    index: number,
    event: React.ChangeEvent<HTMLInputElement>
  ) {
    const allNumbers = [...numbers]

    allNumbers[index].description = event.target.value

    setNumbers(allNumbers)
  }

  // EMAILS FUNCTIONS
  function handlerChangeEmail(
    index: number,
    event: React.ChangeEvent<HTMLInputElement>
  ) {
    const allEmails = [...emails]

    allEmails[index].email = event.target.value

    setEmails(allEmails)
  }

  function handlerChangeEmailDescription(
    index: number,
    event: React.ChangeEvent<HTMLInputElement>
  ) {
    const allEmails = [...emails]

    allEmails[index].description = event.target.value

    setEmails(allEmails)
  }

  function handleGetMethod() {
    const local = history.location.pathname

    if (local.indexOf('create') !== -1) {
      setLocal('create')
    } else {
      setLocal('update')
    }
  }

  function handleDefinedAge(e: React.ChangeEvent<HTMLInputElement>) {
    if (e.target.value.length === 10) {
      const age = calculateAge(
        format(new Date(e.target.value), 'dd/MM/yyyy', {
          locale: LocaleApp
        })
      )
      setAgeUser(String(age))
    } else if (e.target.value.length < 10) {
      setAgeUser('')
    }
  }

  // Close modal
  const handleCloseModal = () => {
    setIsOpen(false)
  }

  // select default country in configs
  useEffect(() => {
    if (decodeToken === undefined) {
      return
    }

    if (decodeToken.clinic_country === 'Brasil') {
      address.setSeledtedBR(true)
    }

    const country = [decodeToken.clinic_country]
    const ArrayCountry = address.optionsContries

    const SelectedDefault = ArrayCountry.map((count: any) =>
      country.find((cou) => {
        if (cou === count.value) {
          address.setSeletedCountry(count)
        }
      })
    )

    console.log(SelectedDefault)
  }, [decodeToken])

  const handleCreateClient: SubmitHandler<FormData> = async (values) => {
    await new Promise((resolve) => setTimeout(resolve, 2000))
    const payloadTelephones = formatNumbers()

    const payloadEmails = formatEmails()

    const payloadHeal = formatHealthInsurance()

    const payloadParent = formatPatientRelatives()

    const payloadAddress = [
      {
        zip_code: address.cep || state?.address?.zip_code || undefined,
        street: address.address || state?.address?.street || undefined,
        number: values.numero || state?.address?.number || undefined,
        district: address.bairro || state?.address?.district || undefined,
        city: address.municipio || state?.address?.city || undefined,
        state:
          address.ufDefault.label ||
          address?.seletedState.label ||
          values.state ||
          undefined,
        country:
          address.seletedCountry.label || state?.address?.country || undefined,
        complement:
          values.complement || state?.address?.observation || undefined,
        index: 0
      }
    ]

    const patient_tags = formatTagsServices()
    const patient_origins = formatOriginsServices()
    const stringValue = imgSrc !== '' ? imgSrc.substring(0, 4) : ''

    const payload = {
      name,
      social_name: socialName,
      birth_date: birth_date === '' ? null : birth_date,
      document:
        valueDocumentSelect !== undefined
          ? { id: valueDocumentSelect?.value }
          : null,
      document_number: numberDocument === '' ? null : numberDocument,
      sex: Number(sex) || undefined,
      observation: values.observation || undefined,
      is_active: values.is_active || undefined,
      alerts: values.alerts || undefined,
      ocuppation: values.occupation || undefined,
      patient_tags,
      patient_origins,
      image: stringValue === 'http' ? undefined : imgSrc
    }

    if (decodeToken?.clinic?.patient_origin && patient_origins?.length === 0) {
      toast({
        status: 'error',
        title: 'Origem de paciente obrigatório!',
        duration: 3000,
        position: 'top',
        description: 'Preencha o campo'
      })

      return
    }

    try {
      if (localMethod === 'create') {
        const id = await clientService.createPatients(payload)

        await apiAuth.post(
          `clinics/patients/${id}/telephones/`,
          payloadTelephones
        )

        await apiAuth.post(
          `clinics/patients/${id}/health-insurances/`,
          payloadHeal
        )

        await apiAuth.post(`clinics/patients/${id}/parents/`, payloadParent)

        await apiAuth.post(`clinics/patients/${id}/emails/`, payloadEmails)

        await apiAuth.post(`clinics/patients/${id}/addresses/`, payloadAddress)

        toast({
          status: 'success',
          title: 'Cadastro realizado com sucesso!',
          duration: 3000,
          position: 'top',
          description: 'Estamos lhe redirecionando'
        })

        setTimeout(() => {
          history.push('/clients')
        }, 2000)
      } else {
        await clientService.updatePatients(payload, state?.id || '')
      }
    } catch (error: any) {
      console.log(error)
      toast({
        status: 'error',
        description: `${error.response?.data?.message}` || 'Error',
        duration: 3000,
        position: 'top',
        title: `${t('Error!')}`
      })
    }
  }

  useEffect(() => {
    handleGetMethod()
  }, [history.location.pathname])

  return (
    <Box overflow="hidden" position="relative">
      <Header>
        <Flex
          mx="auto"
          w="100%"
          direction="row"
          d="flex"
          my="6"
          align="center"
          justify="space-around"
        >
          <Box mx="auto">
            <Text fontSize="24px" fontWeight="600" height="100%">
              {localMethod === 'create' ? t('create.title') : t('updateTitle')}
            </Text>
          </Box>
          <Stack direction="row" align="center" justify="center">
            <ArrowBack href="/clients">
              <Text fontSize="md" fontWeight="bold">
                Voltar
              </Text>
            </ArrowBack>
          </Stack>
        </Flex>
      </Header>
      <Sidebar />
      <>
        <Flex
          flex="1"
          mx="auto"
          as="form"
          ml={isWideVersion ? '75px' : '2'}
          mt="15px"
          mb="8px"
          mr="2"
          bg="white"
          h="cal(100vh - 60px)"
          onSubmit={handleSubmit(handleCreateClient)}
        >
          <Stack spacing="4" w="100%">
            <CardContainer
              title={t(
                'pageClients.PageNovoCliente.containerOne.titleContainer'
              )}
            >
              <Flex flexDirection="row">
                <Flex flexDirection="column">
                  {/* Image for user */}
                  {imgSrc ? <ImageComponent /> : <Dropzone />}
                  {/* Open Modal Webcam */}
                  <Button
                    size="md"
                    fontSize="md"
                    bg="blue.300"
                    color="#fff"
                    onClick={() => setIsOpen(true)}
                  >
                    {t('pageClients.PageNovoCliente.btnWebcam')}
                  </Button>
                </Flex>
                <Flex
                  w="100%"
                  ml="6"
                  flexDirection="column"
                  justify="space-around"
                >
                  <SimpleGrid minChildWidth="320px">
                    <Stack direction="row" my="2" spacing="6">
                      <Box w="50%">
                        <Input
                          label={t(
                            'pageClients.PageNovoCliente.containerOne.iNome'
                          )}
                          isRequired
                          name="name"
                          value={name}
                          onChange={(event) => {
                            setName(event.target.value.toUpperCase())
                            const { length } = event.currentTarget.value

                            if (length <= 20) {
                              setSocialName(
                                event.currentTarget.value.toUpperCase()
                              )
                            }
                          }}
                        />
                      </Box>
                      <Box w="240px">
                        <Input
                          label={t(
                            'pageClients.PageNovoCliente.containerOne.iSocialName'
                          )}
                          isRequired
                          name="socialName"
                          value={socialName}
                          maxLength={20}
                          onChange={(event) =>
                            setSocialName(event.target.value.toUpperCase())
                          }
                        />
                      </Box>
                      <Box w="200px">
                        <Input
                          label={t(
                            'pageClients.PageNovoCliente.containerOne.iDataNasc'
                          )}
                          type="date"
                          handleChange={(e) => {
                            setBirthDate(e.target.value)
                            handleDefinedAge(e)
                          }}
                          name="birth_date"
                          value={birth_date}
                          isRequired={decodeToken?.clinic?.patient_birth_date}
                          defaultValue={state?.birth_date}
                        />
                      </Box>
                    </Stack>
                  </SimpleGrid>

                  <Stack
                    direction="row"
                    my="2"
                    spacing="6"
                    w="100%"
                    justify="space-between"
                  >
                    <Box w="100px">
                      <Input
                        label={t(
                          'pageClients.PageNovoCliente.containerOne.iIdade'
                        )}
                        disabled
                        value={ageUser}
                        name="age"
                      />
                    </Box>
                    <Box w="30%">
                      <SelectComponent
                        title={t(
                          'pageClients.PageNovoCliente.containerOne.iTDoc'
                        )}
                        placeholder="Ex: CPF"
                        value={valueDocumentSelect}
                        isRequired={decodeToken?.clinic?.patient_document}
                        onChange={(event: DocumentSelect) => {
                          setValueDocumentSelect(event)

                          setTypeDocument(event?.regex)
                        }}
                        options={documents}
                        name="document_type"
                      />
                    </Box>
                    <Box w="40%">
                      <TextFieldControlled
                        label={t(
                          'pageClients.PageNovoCliente.containerOne.iDoc'
                        )}
                        mask={typeDocument}
                        textAlign="left"
                        name="document_number"
                        isRequired={decodeToken?.clinic?.patient_document}
                        placeholder={t(
                          'pageClients.PageNovoCliente.containerOne.iDoc'
                        )}
                        initialValue={numberDocument}
                        onKeyUp={(
                          event: React.KeyboardEvent<HTMLInputElement>
                        ) => {
                          setNumberDocument(event.currentTarget.value)
                        }}
                      />
                    </Box>
                    <Flex
                      px="4"
                      pt="4"
                      border="1px solid #d1d1d6"
                      align="center"
                      justify="center"
                      borderRadius={8}
                    >
                      <RadioGroup
                        value={sex}
                        onChange={(event: any) => setSex(event)}
                        defaultValue={sex}
                      >
                        <Stack spacing={4} direction="row">
                          <ChakraRadio value="1">
                            {t(
                              'pageClients.PageNovoCliente.containerOne.TiposSexo.m'
                            )}
                          </ChakraRadio>
                          <ChakraRadio value="2">
                            {t(
                              'pageClients.PageNovoCliente.containerOne.TiposSexo.f'
                            )}
                          </ChakraRadio>
                        </Stack>
                      </RadioGroup>
                    </Flex>
                  </Stack>
                </Flex>
              </Flex>

              <Stack direction="row" my="2" spacing="6">
                <Box w="50%">
                  <Input
                    label={t(
                      'pageClients.PageNovoCliente.containerOne.iTrabalho'
                    )}
                    {...register('occupation')}
                    defaultValue={state?.ocuppation}
                    error={errors.occupation}
                  />
                </Box>

                <Box w="50%">
                  <SelectComponent
                    title="Origens"
                    options={origensArray}
                    placeholder={t('placeholder.origins')}
                    isMulti
                    values={patientsOrigins}
                    isRequired={decodeToken?.clinic?.patient_origin}
                    onChange={(event: any) => handleSelectedOrigins(event)}
                    name="origins"
                    noOptionsText="Nenhuma origem encontrada"
                  />
                </Box>
              </Stack>

              <Stack direction="row" my="2" spacing="6">
                <Box w="100%">
                  <SelectComponent
                    title="Tags"
                    options={tagArray}
                    isMulti
                    placeholder={t('placeholder.tags')}
                    values={patientsTags}
                    onChange={(event: any) => handleSelectedTags(event)}
                    name="tags"
                    noOptionsText="Nenhuma tag encontrada"
                  />
                </Box>
              </Stack>

              <Stack direction="row" my="2" spacing="6">
                <Box w="100%">
                  <Text fontSize="sm" my="2">
                    {t('pageClients.PageNovoCliente.containerOne.iObsMedical')}
                  </Text>
                  <Textarea
                    {...register('alerts')}
                    defaultValue={state?.alerts}
                    placeholder={t('placeholder.observationMedical')}
                  />
                </Box>
                <Box w="100%">
                  <Text fontSize="sm" my="2">
                    {t('pageClients.PageNovoCliente.containerOne.iObs')}
                  </Text>
                  <Textarea
                    {...register('observation')}
                    defaultValue={state?.observation}
                    placeholder={t('placeholder.observation')}
                  />
                </Box>
              </Stack>
            </CardContainer>

            <CardContainer
              title={t('pageClients.PageNovoCliente.containerTwo.title')}
            >
              <Stack my="2" spacing="6">
                {numbers.map((number, index: number) => (
                  <Stack direction="row" key={`number${index}`}>
                    <Stack direction="row" w="100%">
                      <InputPhone2
                        label={`${t(
                          'pageClients.PageNovoCliente.containerTwo.tele'
                        )} ${index + 1}`}
                        defaultValues={{
                          ddi: numbers[index].ddi_country
                        }}
                        type="text"
                        size="md"
                        name="number"
                        getValues={(value) => {
                          const values = [...numbers]
                          values[index].ddi_country = value?.ddi_country
                          setNumbers(values)
                        }}
                        value={numbers[index].contact}
                        onChange={(e) => handleChangeInputNumbers(index, e)}
                      />
                      {numbers[index].contact.length > 5 && (
                        <Input
                          name="descriptionContact"
                          value={numbers[index].description}
                          label={t(
                            'pageClients.PageNovoCliente.containerTwo.description'
                          )}
                          onChange={(event) =>
                            handlerChangeDescriptionContact(index, event)
                          }
                        />
                      )}
                      {/*                       <Flex
                        pt="4"
                        direction="column"
                        align="center"
                        justify="center"
                      >
                        {numbers.length > 1 && (
                          <Box>
                            <Button
                              type="button"
                              colorScheme="red"
                              size="xs"
                              onClick={() => handleRemoveNumbers(index)}
                              mb="2"
                            >
                              -
                            </Button>
                          </Box>
                        )}
                        <Box>
                          <Button
                            bg="green.400"
                            color="#fff"
                            _hover={{
                              bg: 'green.400',
                              filter: 'brightness(0.7)'
                            }}
                            _active={{
                              bg: 'green.400',
                              filter: 'brightness(0.7)'
                            }}
                            type="button"
                            size="xs"
                            onClick={() => handleAddNumbers()}
                          >
                            +
                          </Button>
                        </Box>
                      </Flex> */}
                    </Stack>
                  </Stack>
                ))}
              </Stack>
            </CardContainer>

            <CardContainer
              title={t('pageClients.PageNovoCliente.containerEmail.title')}
            >
              <Stack direction="row" my="2" spacing="6">
                <Input
                  label={t('pageClients.PageNovoCliente.containerEmail.email')}
                  name="email"
                  type="email"
                  size="md"
                  value={emails[0].email}
                  onChange={(event) => handlerChangeEmail(0, event)}
                />
                {emails[0].email.length > 5 && (
                  <Input
                    label={t(
                      'pageClients.PageNovoCliente.containerEmail.description'
                    )}
                    name="descriptionEmail"
                    value={emails[0].description}
                    onChange={(event) =>
                      handlerChangeEmailDescription(0, event)
                    }
                  />
                )}
              </Stack>
            </CardContainer>

            <CardContainer
              title={t('pageClients.PageNovoCliente.containerThree.title')}
            >
              <Stack direction="row" my="2" spacing="2">
                <Box w="30%">
                  <SelectComponent
                    title={t(
                      'pageClients.PageNovoCliente.containerThree.iPais'
                    )}
                    placeholder="Selecione um país"
                    options={address.optionsContries}
                    name="pais"
                    value={address.seletedCountry}
                    onChange={(e: any) =>
                      address.handleBRSelectedAndShowStates(e)
                    }
                  />
                </Box>
                <Box>
                  <Input
                    label={t('pageClients.PageNovoCliente.containerThree.iCEP')}
                    type="number"
                    name="zip_code"
                    isDisabled={address.loadingCompletAddress}
                    value={address.cep}
                    onChange={(e) =>
                      address.handleCompletAddress(e.target.value)
                    }
                  />
                </Box>
                <Box w="40%">
                  <Input
                    label={t('pageClients.PageNovoCliente.containerThree.iRua')}
                    name="street"
                    value={address.address}
                    onChange={(e) => address.setAddress(e.target.value)}
                  />
                </Box>
                <Box>
                  <Input
                    label={t(
                      'pageClients.PageNovoCliente.containerThree.iNumero'
                    )}
                    type="number"
                    defaultValue={state?.address?.number}
                    {...register('numero')}
                    error={errors.numero}
                  />
                </Box>
              </Stack>
              <Stack direction="row" my="2" spacing="2">
                <Box w="30%">
                  <Input
                    label={t(
                      'pageClients.PageNovoCliente.containerThree.iComplemento'
                    )}
                    {...register('complement')}
                    defaultValue={state?.address?.observation}
                    error={errors.complement}
                  />
                </Box>
                <Box>
                  <Input
                    label={t(
                      'pageClients.PageNovoCliente.containerThree.iBairro'
                    )}
                    name="neighborhood"
                    value={address.bairro}
                    onChange={(e) => address.setBairro(e.target.value)}
                  />
                </Box>
                <Box w="30%">
                  <Input
                    label={t(
                      'pageClients.PageNovoCliente.containerThree.iCidade'
                    )}
                    name="city"
                    value={address.municipio}
                    onChange={(e) => address.setMunicipio(e.target.value)}
                  />
                </Box>
                {address.selectedBR ? (
                  <Box w="30%">
                    <SelectComponent
                      title={t(
                        'pageClients.PageNovoCliente.containerThree.iEstado'
                      )}
                      placeholder="Selecione um estado"
                      options={address.ufs}
                      name="state"
                      value={address.seletedState}
                      onChange={(e: any) => address.setSelectedState(e)}
                    />
                  </Box>
                ) : (
                  <Box w="30%">
                    <Input
                      label={t(
                        'pageClients.PageNovoCliente.containerThree.iEstado'
                      )}
                      {...register('state')}
                      defaultValue={state?.address?.state}
                      error={errors.state}
                    />
                  </Box>
                )}
              </Stack>
            </CardContainer>

            <CardContainer
              title={t('pageClients.PageNovoCliente.containerFive.title')}
            >
              <Stack spacing="6">
                {healthInsurance.map((health, index: number) => (
                  <Stack
                    w="100%"
                    direction="row"
                    my="2"
                    spacing="6"
                    key={`health${index}`}
                  >
                    <Box w="40%">
                      <SelectComponent
                        title={t(
                          'pageClients.PageNovoCliente.containerFive.iConvenios'
                        )}
                        placeholder={t('placeholder.covenants')}
                        options={covenantsUpdate.map((con) => {
                          return {
                            value: con.id,
                            label: con.name
                          }
                        })}
                        value={health.healthInsurance}
                        onChange={(event: any) =>
                          handleSelectConvenio(index, event)
                        }
                        name="health_insurance"
                      />
                    </Box>
                    <Stack direction="row" spacing="4" flex="1">
                      <Box w="100%">
                        <Input
                          label={t(
                            'pageClients.PageNovoCliente.containerFive.iNumero'
                          )}
                          isRequired={isRequiredReturnForForm(
                            health.healthInsurance
                          )}
                          value={health.healthInsuranceNumber || ''}
                          onChange={(event) =>
                            handleInputConvenio(index, event)
                          }
                          name="health_insurance_number"
                        />
                      </Box>
                      <Flex
                        pt="4"
                        direction="column"
                        align="center"
                        justify="center"
                      >
                        {healthInsurance.length > 1 && (
                          <Box>
                            <Button
                              type="button"
                              colorScheme="red"
                              size="xs"
                              onClick={() => removeFieldsConvenio(index)}
                              mb="2"
                            >
                              -
                            </Button>
                          </Box>
                        )}
                        <Box>
                          <Button
                            bg="green.400"
                            color="#fff"
                            _hover={{
                              bg: 'green.400',
                              filter: 'brightness(0.7)'
                            }}
                            _active={{
                              bg: 'green.400',
                              filter: 'brightness(0.7)'
                            }}
                            type="button"
                            size="xs"
                            onClick={() => addFieldsConvenio()}
                          >
                            +
                          </Button>
                        </Box>
                      </Flex>
                    </Stack>
                  </Stack>
                ))}
              </Stack>
            </CardContainer>

            <CardContainer
              title={t('pageClients.PageNovoCliente.containerFour.title')}
            >
              <Stack spacing="6">
                {patientRelatives.map((patient, index: number) => (
                  <Stack
                    w="100%"
                    direction="row"
                    my="2"
                    spacing="6"
                    key={`patient${index}`}
                  >
                    <Box w="60%">
                      <SelectComponentAsync
                        title={t(
                          'pageClients.PageNovoCliente.containerFour.iNome'
                        )}
                        placeholder={t('placeholder.family')}
                        loadOptions={(values: string) =>
                          clientService.getClientsServices(values)
                        }
                        value={patient.patientRelatives}
                        onChange={(e: any) =>
                          handleSelectedRelativesNames(index, e)
                        }
                        name="patient_relatives"
                      />
                    </Box>
                    <Stack direction="row" spacing="4" flex="1">
                      <Box w="100%">
                        <Select
                          label={t(
                            'pageClients.PageNovoCliente.containerFour.iGrauP'
                          )}
                          placeholder="Ex: Pai"
                          isRequired={isRequiredReturnForForm(
                            patient.patientRelatives
                          )}
                          options={optionsFamily}
                          value={patient.patientDegree}
                          onChange={(e: any) =>
                            handleSelectRelativesFamily(index, e)
                          }
                          name="patient_degree"
                        />
                      </Box>
                      <Flex
                        pt="4"
                        direction="column"
                        align="center"
                        justify="center"
                      >
                        {patientRelatives.length > 1 && (
                          <Box>
                            <Button
                              type="button"
                              colorScheme="red"
                              size="xs"
                              onClick={() => removeFieldsRelatives(index)}
                              mb="2"
                            >
                              -
                            </Button>
                          </Box>
                        )}
                        <Box>
                          <Button
                            bg="green.400"
                            color="#fff"
                            _hover={{
                              bg: 'green.400',
                              filter: 'brightness(0.7)'
                            }}
                            _active={{
                              bg: 'green.400',
                              filter: 'brightness(0.7)'
                            }}
                            type="button"
                            size="xs"
                            onClick={() => addFieldsRelatives()}
                          >
                            +
                          </Button>
                        </Box>
                      </Flex>
                    </Stack>
                  </Stack>
                ))}
              </Stack>
            </CardContainer>

            <Flex mt="6" w="100%" align="flex-end" justifyContent="flex-end">
              <Button
                type="submit"
                size="md"
                fontSize="md"
                bg="blue.300"
                color="#fff"
                isLoading={formState.isSubmitting}
              >
                {t('pageClients.PageNovoCliente.buttonSave')}
              </Button>
            </Flex>

            <Stack w="100%" my="2" h="300px" />
          </Stack>
        </Flex>
        <ModalWebcam
          title={t('modal.title')}
          isOpen={isOpen}
          onClose={handleCloseModal}
        >
          <Flex mx="2px" h="500px">
            <WebcamCapture />
          </Flex>
        </ModalWebcam>
      </>
    </Box>
  )
}

/* eslint-disable react-hooks/exhaustive-deps */
import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons'
import {
  Box,
  Button,
  Center,
  Divider,
  Flex,
  Heading,
  HStack,
  Skeleton,
  Stack,
  Table,
  Tag,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
  useBreakpointValue,
  useToast,
  VStack
} from '@chakra-ui/react'
import { addMonths, format, subMonths } from 'date-fns'
import React, { FC, useCallback, useEffect, useState } from 'react'
import { v4 as uuidV4 } from 'uuid'
import { useTranslation } from 'react-i18next'
import { AiFillLock, AiFillUnlock } from 'react-icons/ai'
import { FiDollarSign, FiEdit } from 'react-icons/fi'
import { Link, useHistory } from 'react-router-dom'
import { Input } from '../../components/Form/Input'
import { TextFieldControlled } from '../../components/Form/TextFieldControlled'
import { ModalArrivedTime } from '../../components/Modal/confirmArrivedTime'
import { CardContainer } from '../../components/molecules/CardContainer'
import { NotFound } from '../../components/molecules/NotFound'
import { MultiSelectMenuFilter } from '../../components/MultiSelectFilters'
import { getDailyBox, useDailyBox } from '../../hooks/dailyBox/useDailyBox'
import { useModal } from '../../hooks/useModal'
import { LayoutBlank } from '../../layouts/blank'
import { apiAuth } from '../../services/apiAuth'
import { formatBrOrDollarDefault } from '../../utils/fns/formatBrOrDollarDefault'
import { useService } from './service'
import { ModalConfirmExclude } from '../../components/Modal/confirmExclude'
import { Pagination } from '../../components/PaginationNew'

// debounce
let timer: any = null

export const DailyBox: FC = () => {
  const [t] = useTranslation('pageDailyBox')
  const toast = useToast()
  const isWideVersion = useBreakpointValue({
    base: false,
    lg: true
  })
  const services = useService()
  const {
    LocaleApp,
    dateInitialDailyBox,
    setDateInitialDailyBox,
    dateFinalDailyBox,
    setDateFinalDailyBox,
    wayReceiveFilterDailyBox,
    searchDailyBox,
    setSearchDailyBox,
    setWayReceiveFilterDaily,
    statusPaymentFilterDailyBox,
    statusConferenceFilterDailyBox,
    setStatusConferenceFilterDailyBox,
    setStatusPaymentFilterDailyBox,
    setGenerateInvoiceFilterDailyBox,
    generateInvoiceFilterDailyBox,
    setNumberTicketDailyBox,
    setPatientNameDailyBox,
    numberTicketDailyBox,
    patientNameDailyBox,
    patientIdDailyBox,
    setPatientIdDailyBox,
    patientReference,
    setPatientReference
  } = useModal()
  const router = useHistory()
  const { state }: any = router.location
  const [currentPage, setCurrentPage] = useState(1)
  const skeletonRepeat = [1, 2, 3, 4, 5, 6, 7]
  const statusOptionsDefault = [
    { id: '1', name: `${t('status.1')}` },
    { id: '2', name: `${t('status.2')}` },
    { id: '3', name: `${t('status.3')}` },
    { id: '4', name: `${t('status.4')}` }
  ]
  const statusConferenceDefault = [
    { id: '2', name: `${t('resultStatus.2')}` },
    { id: '3', name: `${t('resultStatus.3')}` },
    { id: '0', name: `${t('resultStatus.null')}` }
  ]
  const statusGenerateInvoice = [
    { id: '1', name: `${t('optionsFilter.1')}` },
    { id: '2', name: `${t('optionsFilter.2')}` },
    { id: 'null', name: `${t('optionsFilter.null')}` }
  ]
  // way actions id
  const [wayId, setWayId] = useState<number>(0)
  const [isLoadingWay, setIsLoadingWay] = useState(false)
  const [isOpenAction, setIsOpenAction] = useState(false)

  // local filters
  const [inicialDate, setInicialDate] = useState(dateInitialDailyBox)
  const [finalDate, setFinalDate] = useState(dateFinalDailyBox)
  const [statusConference, setStatusConference] = useState(
    statusConferenceFilterDailyBox
  )
  const [statusPayment, setStatusPayment] = useState(
    statusPaymentFilterDailyBox
  )
  const [generateInvoice, setGenerateInvoice] = useState(
    generateInvoiceFilterDailyBox
  )
  const [ticketNumber, setTicketNumber] = useState(numberTicketDailyBox)
  const [patientName, setPatientName] = useState(patientNameDailyBox)
  const [patientId, setPatientId] = useState(patientIdDailyBox)

  useEffect(() => {
    if (state?.patient_id !== undefined) {
      setPatientIdDailyBox(state.patient_id)
      setPatientReference(state.patient_name)
      setPatientId(state?.patient_id)
      setInicialDate('')
      setFinalDate('')
      setDateFinalDailyBox('')
      setDateInitialDailyBox('')
    }
  }, [])

  const dateDefault = new Date(
    Number(inicialDate?.substring(0, 4)),
    Number(inicialDate?.substring(5, 7)) - 1,
    Number(inicialDate?.substring(8, 10))
  )

  const maxDate = addMonths(dateDefault, 13)

  const getDailyBoxData = useDailyBox(
    currentPage,
    getDailyBox(
      currentPage,
      searchDailyBox,
      patientId !== '' ? undefined : dateInitialDailyBox,
      patientId !== '' ? undefined : dateFinalDailyBox,
      wayReceiveFilterDailyBox,
      statusConferenceFilterDailyBox,
      statusPaymentFilterDailyBox,
      generateInvoiceFilterDailyBox,
      numberTicketDailyBox,
      patientNameDailyBox,
      patientId || undefined
    ),
    searchDailyBox,
    dateInitialDailyBox,
    dateFinalDailyBox,
    wayReceiveFilterDailyBox,
    statusConferenceFilterDailyBox,
    statusPaymentFilterDailyBox,
    generateInvoiceFilterDailyBox,
    numberTicketDailyBox,
    patientNameDailyBox,
    patientId || undefined
  )

  const SearchInputCallbackValue = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      const { value } = event.currentTarget

      clearTimeout(timer)

      timer = setTimeout(() => {
        setCurrentPage(1)
        setSearchDailyBox(value)
      }, 500)
    },
    []
  )

  // jxs status item ticket
  const returnJsxElementStatusTicket = (
    numberPaymentStatus: number | null | undefined
  ) => {
    if (numberPaymentStatus === 1) {
      return (
        <Tooltip
          label={t('statusTicket.1')}
          aria-label={t('statusTicket.1')}
          hasArrow
          placement="top-start"
        >
          <Box w="24px">
            <FiDollarSign
              color="#c71717f4"
              size={16}
              style={{ cursor: 'default' }}
            />
          </Box>
        </Tooltip>
      )
    }

    if (numberPaymentStatus === 2) {
      return (
        <Tooltip
          label={t('statusTicket.2')}
          aria-label={t('statusTicket.2')}
          hasArrow
          placement="top-start"
        >
          <Box w="24px">
            <FiDollarSign
              color="#56c20ff4"
              size={16}
              style={{ cursor: 'default' }}
            />
          </Box>
        </Tooltip>
      )
    }

    if (numberPaymentStatus === 3) {
      return (
        <Tooltip
          label={t('statusTicket.3')}
          aria-label={t('statusTicket.3')}
          hasArrow
          placement="top-start"
        >
          <Box w="24px">
            <FiDollarSign
              color="#fd7011f0"
              size={16}
              style={{ cursor: 'default' }}
            />
          </Box>
        </Tooltip>
      )
    }

    if (numberPaymentStatus === 4) {
      return (
        <Tooltip
          label={t('statusTicket.4')}
          aria-label={t('statusTicket.4')}
          hasArrow
          placement="top-start"
        >
          <Box w="24px">
            <FiDollarSign
              color="#192facdc"
              size={16}
              style={{ cursor: 'default' }}
            />
          </Box>
        </Tooltip>
      )
    }

    if (numberPaymentStatus === 5) {
      return (
        <Tooltip
          label={t('statusTicket.5')}
          aria-label={t('statusTicket.5')}
          hasArrow
          placement="top-start"
        >
          <Box w="24px">
            <FiDollarSign
              color="#121214"
              size={16}
              style={{ cursor: 'default' }}
            />
          </Box>
        </Tooltip>
      )
    }

    if (numberPaymentStatus === null) {
      return (
        <Box w="24px">
          <Text>---</Text>
        </Box>
      )
    }

    return (
      <Tooltip
        label={t('statusTicket.null')}
        aria-label={t('statusTicket.null')}
        hasArrow
        placement="top-start"
      >
        <Box w="24px">
          <FiDollarSign
            color="#9699B0"
            size={16}
            style={{ cursor: 'default' }}
          />
        </Box>
      </Tooltip>
    )
  }

  // function conference
  // change here
  const handlerConferenceWayToReceive = async (id: number) => {
    setIsLoadingWay(true)

    try {
      const { data } = await apiAuth.post(
        `clinics/tickets/daily-conferre-all/`,
        {},
        {
          params: {
            startDate: dateInitialDailyBox,
            finishDate: dateFinalDailyBox,
            way_to_receive_id: id
          }
        }
      )
      toast({
        duration: 3000,
        description: 'Ação realizada com sucesso!',
        position: 'top-right',
        title: 'Atenção!',
        status: 'success'
      })

      getDailyBoxData?.refetch()
    } catch (error: any) {
      toast({
        duration: 3000,
        description: `${error?.response?.data?.detail}` || 'Ação não realizada',
        position: 'top-right',
        title: 'Atenção!',
        status: 'error'
      })
    } finally {
      setIsLoadingWay(false)
    }
  }

  // close modal
  const closeModalAction = () => {
    setWayId(0)

    setIsOpenAction(false)
  }

  // open modal
  const openModalAction = (id: number) => {
    setWayId(id)

    setIsOpenAction(true)
  }

  // apply filters date in context date search field
  const handlerApplyFiltersDate = () => {
    let throwError = false

    if (ticketNumber === '') {
      if (inicialDate === '') {
        toast({
          title: 'Data inicial obrigatória',
          position: 'top-right',
          status: 'error'
        })

        throwError = true
      }

      if (finalDate === '') {
        toast({
          title: 'Data final obrigatória',
          position: 'top-right',
          status: 'error'
        })

        throwError = true
      }
    }

    if (patientId === '' && ticketNumber === '') {
      if (inicialDate === '') {
        toast({
          title: 'Data inicial obrigatória',
          position: 'top-right',
          status: 'error'
        })

        throwError = true
      }

      if (finalDate === '') {
        toast({
          title: 'Data final obrigatória',
          position: 'top-right',
          status: 'error'
        })

        throwError = true
      }
    }

    if (throwError) {
      toast({
        title: 'Revise os valores de filtro.',
        position: 'top-right',
        status: 'error'
      })

      return
    }

    if (ticketNumber !== '') {
      setPatientId('')
      setPatientReference('')
    }

    if (inicialDate !== '') {
      setPatientId('')
      setPatientReference('')
    }

    if (finalDate !== '') {
      setPatientId('')
      setPatientReference('')
    }

    setDateInitialDailyBox(inicialDate)
    setDateFinalDailyBox(finalDate)
    setStatusConferenceFilterDailyBox(statusConference)
    setStatusPaymentFilterDailyBox(statusPayment)
    setGenerateInvoiceFilterDailyBox(generateInvoice)
    setNumberTicketDailyBox(ticketNumber)
    setPatientNameDailyBox(patientName)
  }

  const returnTotalReceived = () => {
    const value =
      formatBrOrDollarDefault(
        getDailyBoxData?.data?.meta?.total_value_way_to_receive?.toFixed(2) ||
          ''
      ) || '---'

    return value
  }

  const returnTotalTicket = () => {
    const value =
      formatBrOrDollarDefault(
        getDailyBoxData?.data?.meta?.tickets_total_value?.toFixed(2) || ''
      ) || '---'

    return value
  }

  const returnTotalPaidTicket = () => {
    const value =
      formatBrOrDollarDefault(
        getDailyBoxData?.data?.meta?.tickets_paid_value?.toFixed(2) || ''
      ) || '---'

    return value
  }

  const returnTotalDebits = () => {
    const total = getDailyBoxData?.data?.meta?.tickets_total_value || 0
    const paid = getDailyBoxData?.data?.meta?.tickets_paid_value || 0

    const value = formatBrOrDollarDefault((total - paid).toFixed(2)) || '---'

    return value
  }

  const verifyFilterApply = () => {
    let isDifferent = false

    if (inicialDate !== dateInitialDailyBox) {
      isDifferent = true
    }

    if (finalDate !== dateFinalDailyBox) {
      isDifferent = true
    }

    if (statusPayment !== statusPaymentFilterDailyBox) {
      isDifferent = true
    }

    if (statusConference !== statusConferenceFilterDailyBox) {
      isDifferent = true
    }

    if (generateInvoice !== generateInvoiceFilterDailyBox) {
      isDifferent = true
    }

    if (ticketNumber !== numberTicketDailyBox) {
      isDifferent = true
    }

    if (patientName !== patientNameDailyBox) {
      isDifferent = true
    }

    return isDifferent
  }

  const isButtonFilters = verifyFilterApply()

  return (
    <>
      <LayoutBlank
        title={t('title')}
        placeholder={t('search')}
        defaultSearch={searchDailyBox}
        capturaChange={(event) => SearchInputCallbackValue(event)}
        noRenderIsActive
        isSearched={false}
      >
        <Flex
          mx="auto"
          w="100%"
          direction="row"
          mb="2"
          p="4"
          justify="space-around"
        >
          <HStack>
            <Flex align="center" px="2" mx="2">
              <Flex mx="8px" align="baseline" justify="baseline">
                <TextFieldControlled
                  textAlign="center"
                  type="date"
                  value={inicialDate}
                  onChange={(event) => {
                    setInicialDate(event.target.value)
                    setFinalDate('')

                    if (event.target.value !== '') {
                      setTicketNumber('')
                    }
                  }}
                  name="date"
                />
              </Flex>
              <Divider w="20px" />
              <Flex mx="8px" align="baseline" justify="baseline">
                <TextFieldControlled
                  textAlign="center"
                  type="date"
                  min={inicialDate}
                  disabled={inicialDate === ''}
                  max={format(maxDate, 'yyyy-MM-dd')}
                  value={finalDate}
                  onChange={(event) => {
                    setFinalDate(event.target.value)
                    if (event.target.value !== '') {
                      setTicketNumber('')
                    }
                  }}
                  name="date"
                />
              </Flex>
            </Flex>
            <MultiSelectMenuFilter
              label="Status de pagamento"
              options={statusOptionsDefault}
              backChange={(values) => {
                setStatusPayment(values)
              }}
              render={() => {
                const statusRender: any = statusPaymentFilterDailyBox
                return statusRender
              }}
            />
            <MultiSelectMenuFilter
              label="Status de conferência"
              options={statusConferenceDefault}
              backChange={(values) => {
                setStatusConference(values)
              }}
              render={() => {
                const statusRender: any = statusConferenceFilterDailyBox
                return statusRender
              }}
            />
            <MultiSelectMenuFilter
              label="Recibo"
              options={statusGenerateInvoice}
              backChange={(values) => {
                setGenerateInvoice(values)
              }}
              render={() => {
                const statusRender: any = generateInvoiceFilterDailyBox
                return statusRender
              }}
            />
            <Flex mx="8px" align="baseline" justify="baseline">
              <TextFieldControlled
                name="number_ticket"
                value={ticketNumber}
                placeholder="Nº do ticket"
                textAlign="left"
                type="number"
                onChange={(event) => {
                  if (event.target.value !== '') {
                    setInicialDate('')
                    setFinalDate('')
                  }

                  setTicketNumber(event.target.value)
                }}
              />
            </Flex>
            <Flex w="270px" mx="8px" align="baseline" justify="baseline">
              <TextFieldControlled
                name="patient_name"
                placeholder={
                  patientReference !== ''
                    ? patientReference
                    : 'Nome do paciente'
                }
                textTransform="uppercase"
                textAlign="left"
                value={patientName}
                onChange={(event) => setPatientName(event.target.value)}
              />
            </Flex>
            {isButtonFilters && (
              <Flex mx="8px" align="baseline" justify="baseline">
                <Button
                  color="white"
                  bg="blue.300"
                  onClick={() => handlerApplyFiltersDate()}
                >
                  Aplicar
                </Button>
              </Flex>
            )}
          </HStack>
          <HStack>
            <MultiSelectMenuFilter
              label="Meio de Recebimento"
              options={
                getDailyBoxData?.data?.meta?.ways_to_receive?.map((way) => {
                  return {
                    id: String(way?.id),
                    name: way?.name
                  }
                }) || []
              }
              backChange={(values) => {
                setWayReceiveFilterDaily(values)
              }}
              render={() => {
                const statusRender: any = wayReceiveFilterDailyBox
                return statusRender
              }}
            />
          </HStack>
        </Flex>
        <Stack w="100%" spacing="4" direction={['row', 'column']}>
          <CardContainer title={t('container2.title')}>
            <Table variant="striped" colorScheme="blue" size="sm">
              <Thead>
                <Tr>
                  <Th fontSize="xs">{t('table1.t1')}</Th>
                  <Th fontSize="xs">{t('table1.t2')}</Th>
                  <Th fontSize="xs">{t('table1.t3')}</Th>
                </Tr>
              </Thead>
              <Tbody>
                {getDailyBoxData?.isLoading ? (
                  <>
                    {skeletonRepeat.map((skeleton) => (
                      <Tr key={skeleton}>
                        <Td>
                          <Skeleton height="20px" />
                        </Td>
                        <Td>
                          <Skeleton height="20px" />
                        </Td>
                        <Td>
                          <Skeleton height="20px" />
                        </Td>
                      </Tr>
                    ))}
                  </>
                ) : (
                  <>
                    {getDailyBoxData?.data?.meta?.ways_to_receive?.map(
                      (item) => (
                        <Tr key={item?.id}>
                          <Td>
                            <Tooltip
                              placement="bottom-start"
                              label={item?.name}
                              aria-label={item?.name}
                            >
                              <Box>
                                <Text
                                  _hover={{ cursor: 'default' }}
                                  noOfLines={1}
                                >
                                  {item?.name}
                                </Text>
                              </Box>
                            </Tooltip>
                          </Td>
                          <Td w="150px" textAlign="right">
                            <Text fontSize="sm" fontWeight="hairline">
                              {formatBrOrDollarDefault(
                                item?.value.toFixed(2) || '0.00'
                              ) || '---'}
                            </Text>
                          </Td>
                          <Td flex={1}>
                            <Button
                              isLoading={isLoadingWay}
                              bg="blue.300"
                              size="xs"
                              color="white"
                              onClick={() => {
                                openModalAction(item?.id)
                              }}
                            >
                              Conferência
                            </Button>
                          </Td>
                        </Tr>
                      )
                    )}
                  </>
                )}
              </Tbody>
            </Table>
            {getDailyBoxData?.data?.meta?.ways_to_receive?.length !== 0 &&
              !getDailyBoxData?.isLoading && (
                <HStack mt="4" w="100%" px="4">
                  <Heading size="sm">Total Recebido:</Heading>
                  <Text>{returnTotalReceived()}</Text>
                </HStack>
              )}
            {getDailyBoxData?.data?.meta?.ways_to_receive?.length === 0 &&
              !getDailyBoxData?.isLoading && <NotFound />}
          </CardContainer>
          <CardContainer title={t('container3.title')}>
            <Table variant="striped" colorScheme="blue" size="sm">
              <Thead>
                <Tr>
                  <Th fontSize="xs">{t('t1')}</Th>
                  <Th fontSize="xs">{t('t9')}</Th>
                  <Th fontSize="xs">{t('t2')}</Th>
                  <Th fontSize="xs">{t('t3')}</Th>
                  <Th fontSize="xs">{t('t4')}</Th>
                  <Th fontSize="xs">{t('t5')}</Th>
                  <Th fontSize="xs">{t('t6')}</Th>
                  <Th fontSize="xs">{t('t7')}</Th>
                  <Th fontSize="xs">{t('t8')}</Th>
                </Tr>
              </Thead>
              <Tbody>
                {getDailyBoxData?.isLoading ? (
                  <>
                    {skeletonRepeat.map((skeleton) => (
                      <Tr key={skeleton}>
                        <Td>
                          <Skeleton height="20px" />
                        </Td>
                        <Td>
                          <Skeleton height="20px" />
                        </Td>
                        <Td>
                          <Skeleton height="20px" />
                        </Td>
                        <Td>
                          <Skeleton height="20px" />
                        </Td>
                        <Td>
                          <Skeleton height="20px" />
                        </Td>
                        <Td>
                          <Skeleton height="20px" />
                        </Td>
                        <Td>
                          <Skeleton height="20px" />
                        </Td>
                        <Td>
                          <Skeleton height="20px" />
                        </Td>
                        <Td>
                          <Skeleton height="20px" />
                        </Td>
                      </Tr>
                    ))}
                  </>
                ) : (
                  <>
                    {getDailyBoxData?.data?.dailyBox.map((item) => (
                      <Tr key={`${item?.id}${uuidV4()}`}>
                        <Td w="150px">
                          <Text>{item?.ticket_number}</Text>
                        </Td>
                        <Td w="150px">
                          <Text>
                            {format(
                              new Date(
                                Number(item?.created_at?.substring(0, 4)),
                                Number(item?.created_at?.substring(5, 7)) - 1,
                                Number(item?.created_at?.substring(8, 10))
                              ),
                              'dd/MM/yyyy'
                            )}
                          </Text>
                        </Td>
                        <Td textAlign="right">
                          <Text fontSize="sm" fontWeight="hairline">
                            {formatBrOrDollarDefault(
                              item?.total_value.toFixed(2) || '0.00'
                            ) || '---'}
                          </Text>
                        </Td>
                        <Td textAlign="right">
                          <Text fontSize="sm" fontWeight="hairline">
                            {formatBrOrDollarDefault(
                              item?.paid_value.toFixed(2) || '0.00'
                            ) || '---'}
                          </Text>
                        </Td>
                        <Td textAlign="right">
                          <HStack
                            textAlign="right"
                            alignItems="end"
                            justifyContent="end"
                          >
                            <Text fontSize="sm" fontWeight="hairline">
                              {formatBrOrDollarDefault(
                                Number(
                                  item?.total_value - item?.paid_value
                                ).toFixed(2) || '0.00'
                              ) || '---'}
                            </Text>
                            {returnJsxElementStatusTicket(item?.status_payment)}
                          </HStack>
                        </Td>
                        <Td>
                          {item.result_status === 2 && (
                            <Td>
                              <Tag bg="#56c20ff4" color="white">
                                <Text fontSize="xs" fontWeight="bold">
                                  {t('resultStatus.2')}
                                </Text>
                              </Tag>
                            </Td>
                          )}
                          {item.result_status === 3 && (
                            <Td>
                              <Tag bg="#c71717f4" color="white">
                                <Text fontSize="xs" fontWeight="bold">
                                  {t('resultStatus.3')}
                                </Text>
                              </Tag>
                            </Td>
                          )}
                          {item?.result_status === null &&
                            item.status_payment !== 5 && (
                              <Td w="150px">
                                <Tag bg="#9699B0" color="white">
                                  <Text fontSize="xs" fontWeight="bold">
                                    {t('resultStatus.null')}
                                  </Text>
                                </Tag>
                              </Td>
                            )}
                        </Td>
                        {item?.generate_invoice === 1 && (
                          <Td textAlign="center">Sim</Td>
                        )}
                        {item?.generate_invoice === 2 && (
                          <Td textAlign="center">Não</Td>
                        )}
                        {!item?.generate_invoice && (
                          <Td textAlign="center">---</Td>
                        )}
                        <Td textAlign="right">
                          <Text>
                            {formatBrOrDollarDefault(
                              String(
                                item?.total_value_invoice !== null &&
                                  item?.total_value_invoice * 100
                              ) || ''
                            ) || '---'}
                          </Text>
                        </Td>
                        <Td>
                          <Link
                            to={{
                              pathname: `/financial/daily-box/conference/${item?.attendances[0]?.id}`,
                              state: item
                            }}
                          >
                            <Tooltip
                              label="Editar"
                              aria-label="editar"
                              hasArrow
                              placement="top-start"
                            >
                              <Box w="24px">
                                <FiEdit
                                  color="#9699B0"
                                  size={16}
                                  style={{ cursor: 'pointer' }}
                                />
                              </Box>
                            </Tooltip>
                          </Link>
                        </Td>
                      </Tr>
                    ))}
                  </>
                )}
              </Tbody>
            </Table>
            {getDailyBoxData?.data?.dailyBox?.length !== 0 &&
              !getDailyBoxData?.isLoading && (
                <VStack w="100%">
                  <HStack mt="4" w="100%" px="4">
                    <Heading size="sm">Valor total dos tickets:</Heading>
                    <Text>{returnTotalTicket()}</Text>
                  </HStack>
                  <HStack mt="4" w="100%" px="4">
                    <Heading size="sm">Valor total pago:</Heading>
                    <Text>{returnTotalPaidTicket()}</Text>
                  </HStack>
                  <HStack mt="4" w="100%" px="4">
                    <Heading size="sm">Valor a receber:</Heading>
                    <Text>{returnTotalDebits()}</Text>
                  </HStack>
                </VStack>
              )}
            {getDailyBoxData?.data?.dailyBox?.length === 0 &&
              !getDailyBoxData?.isLoading && <NotFound />}
            {!(
              getDailyBoxData.data?.totalCountOfPage === 0 &&
              !getDailyBoxData.isLoading
            ) && (
              <Pagination
                totalCountOfRegisters={
                  getDailyBoxData.data?.totalCountOfPage || 0
                }
                currentPage={currentPage}
                onPageChange={setCurrentPage}
              />
            )}
          </CardContainer>
        </Stack>
        <>
          <ModalConfirmExclude
            isOpen={isOpenAction}
            onClose={() => {
              closeModalAction()
            }}
            title={t('modalAction.title')}
            onConfirmExclude={async () => {
              if (wayId === 0) {
                return
              }

              await handlerConferenceWayToReceive(wayId)

              closeModalAction()
            }}
          >
            {t('modalAction.message')}
          </ModalConfirmExclude>
        </>
      </LayoutBlank>
    </>
  )
}

import React, {
  useState,
  useCallback,
  forwardRef,
  ForwardRefRenderFunction,
  useEffect
} from 'react'
import {
  Text,
  FormErrorMessage,
  FormControl,
  FormLabel
} from '@chakra-ui/react'
import Select, {
  OptionTypeBase,
  Props as SelectProps,
  ControlProps
} from 'react-select'
import { FieldError } from 'react-hook-form'

import { Container, customStyles } from './styles'

interface Props extends SelectProps<OptionTypeBase> {
  title: string
  defaultValueSelect?: {
    value?: string
    label?: string
    sigla?: string
    code?: string
    mask?: string | string[]
  }
  name?: string
  placeholder?: string
  isRequired?: boolean
  medium?: boolean
  value?: OptionTypeBase | readonly OptionTypeBase[] | null | undefined
  error?: FieldError
}

const SelectC: ForwardRefRenderFunction<HTMLSelectElement, Props> = (
  {
    name,
    defaultValueSelect,
    title,
    placeholder,
    value,
    isRequired = false,
    medium = true,
    error,
    ...rest
  },
  ref
) => {
  const [isFocused, setIsFocused] = useState(false)

  const handleFocus = useCallback(() => {
    setIsFocused(true)
  }, [])

  const handleBlur = useCallback(() => {
    setIsFocused(false)
  }, [])

  return (
    <FormControl isInvalid={!!error} isRequired={isRequired}>
      <Container medium={medium}>
        {!!title && (
          <FormLabel fontSize="14px" htmlFor={name}>
            {title}
          </FormLabel>
        )}
        <Select
          onFocus={handleFocus}
          value={value}
          onBlur={handleBlur}
          isFocused={isFocused}
          name={name}
          id={name}
          noOptionsMessage={() => 'sem opções para selecionar'}
          placeholder={placeholder}
          defaultValue={defaultValueSelect}
          classNamePrefix="react-select"
          theme={(theme) => ({
            ...theme,
            colors: {
              ...theme.colors,
              primary: ' #3182CE'
            }
          })}
          {...rest}
        />
        {!!error && <FormErrorMessage>{error.message}</FormErrorMessage>}
      </Container>
    </FormControl>
  )
}

export const SelectComponent = forwardRef(SelectC)

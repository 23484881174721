/* eslint-disable camelcase */
import { useQuery } from 'react-query'
import { apiAuth } from '../../services/apiAuth'

export type BillsToReceive = {
  id: number
  scheduled_arrival_time: string
  status: number
  patient_name: string
  patient_social_name: string
  specialist_name: string
  block_guide: boolean
  ticket_number: number | null
  ticket_status_payment: number | null
  patient_id: number
  ticket: {
    status_payment: number | null
    result_status: number
    ticket_number: number | null
    discount: number | null
    addition: number | null
    observation_financial: string | null
    observation_secretary: string | null
    total_value: number
    paid_value: number
  }
}

export type ResponseGetBillsToReceive = {
  billsToReceive: BillsToReceive[]
  totalCountOfPage: number
}

// new filters

export const getBillsToReceive = async (
  page: number,
  search: string = '',
  startAt: string = '',
  endAt: string = '',
  specialistId: String[] | any = [],
  status: String[] | any = [],
  statusPayment: String[] | any = [],
  ticket_number: string = '',
  guide: string = '',
  block_guide: string = '',
  isHaveTicket: string = ''
): Promise<ResponseGetBillsToReceive> => {
  try {
    const { data } = await apiAuth.get<Array<BillsToReceive>>(
      `clinics/attendances/financial/`,
      {
        params: {
          page,
          keyword: search,
          startDate: startAt,
          finishDate: endAt,
          specialistId: specialistId?.join(','),
          status: status?.join(','),
          status_payment: statusPayment?.join(','),
          ticket_number,
          guide,
          block_guide,
          is_have_ticket: isHaveTicket
        }
      }
    )

    const totalCountOfPage = data?.length || 0
    const billsToReceive: BillsToReceive[] = data || []

    return {
      billsToReceive,
      totalCountOfPage
    }
  } catch (error: any) {
    console.log(error)

    const billsToReceive: BillsToReceive[] = []
    const totalCountOfPage = 0
    return {
      billsToReceive,
      totalCountOfPage
    }
  }
}

export function useBillsToReceive(
  page: number,
  functionLoading: Promise<ResponseGetBillsToReceive>,
  search: string,
  startAt: string = '',
  endAt: string = '',
  specialistId: String[] | String = [],
  status: String[] | String = [],
  statusPayment: String[] | String = [],
  ticket_number: string = '',
  guide: string = '',
  block_guide: string = '',
  is_have_ticket: string = '',
  options?: {}
) {
  return useQuery(
    [
      'billsToReceive',
      page,
      search,
      startAt,
      endAt,
      specialistId,
      status,
      statusPayment,
      guide,
      block_guide,
      is_have_ticket,
      ticket_number
    ],
    () => functionLoading,
    {
      staleTime: 1000 * 60 * 5, // 1 seconds
      cacheTime: 1000 * 60 * 5, // 5 minutes
      ...options
    }
  )
}

/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Button,
  Center,
  Flex,
  Heading,
  Skeleton,
  Stack,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr
} from '@chakra-ui/react'
import format from 'date-fns/format'
import React, { FC, useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FiDollarSign, FiEdit } from 'react-icons/fi'
import { Link } from 'react-router-dom'
import { Input } from '../../components/Form/Input'
import { ModalArrivedTime } from '../../components/Modal/confirmArrivedTime'
import { NotFound } from '../../components/molecules/NotFound'
import { MultiSelectMenuFilter } from '../../components/MultiSelectFilters'
import { Pagination } from '../../components/PaginationNew'
import {
  Control,
  getTaxControl,
  useTaxControl
} from '../../hooks/taxControl/useTaxControl'
import { useModal } from '../../hooks/useModal'
import { LayoutBlank } from '../../layouts/blank'
import { formatBrOrDollarDefault } from '../../utils/fns/formatBrOrDollarDefault'
import { useService } from './service'

// debounce
let timer: any = null

export const TaxControl: FC = () => {
  const [t] = useTranslation('pageTaxControl')
  const services = useService()
  const {
    isOpenTaxControl,
    handlerCloseModal,
    startAtTaxControl,
    endAtTaxControl,
    statusTaxControl,
    specialistIdsTaxControl,
    handlerApplyFiltersTaxControl,
    statusPaymentTaxControl,
    handlerRemoveFiltersTaxControl
  } = useModal()
  const [currentPage, setCurrentPage] = useState(1)
  const [searchState, setSearchState] = useState('')
  const skeletonRepeat = [1, 2, 3, 4, 5, 6, 7]
  const statusOptionsDefault = [
    { value: 1, label: `${t('options.1')}` },
    { value: 2, label: `${t('options.2')}` },
    { value: null, label: `${t('options.null')}` }
  ]
  const statusOptionsDefaultPayment = [
    { value: 1, label: `${t('statusTicket.1')}` },
    { value: 2, label: `${t('statusTicket.2')}` },
    { value: 3, label: `${t('statusTicket.3')}` },
    { value: 4, label: `${t('statusTicket.4')}` },
    { value: 5, label: `${t('statusTicket.5')}` },
    { value: null, label: `${t('statusTicket.null')}` }
  ]
  // filters local
  const [startAt, setStartAt] = useState(startAtTaxControl)
  const [endAt, setEndAt] = useState(endAtTaxControl)
  const [specialistIds, setSpecialistIds] = useState<any>(
    specialistIdsTaxControl
  )
  const [status, setStatus] = useState<any>(statusTaxControl)
  const [statusPayment, setStatusPayment] = useState<any>(
    statusPaymentTaxControl
  )

  const getBillsToReceiveData = useTaxControl(
    currentPage,
    getTaxControl(
      currentPage,
      searchState,
      startAtTaxControl,
      endAtTaxControl,
      specialistIdsTaxControl,
      statusTaxControl,
      statusPaymentTaxControl
    ),
    searchState,
    startAtTaxControl,
    endAtTaxControl,
    specialistIdsTaxControl,
    statusTaxControl,
    statusPaymentTaxControl
  )

  const SearchInputCallbackValue = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      const { value } = event.currentTarget

      clearTimeout(timer)

      timer = setTimeout(() => {
        setSearchState(value)
      }, 500)
    },
    []
  )

  // close filter modal
  const handlerCloseModalTaxControl = () => {
    handlerCloseModal('TaxControl')
  }

  // get filters specialist
  const getFiltersSpecialist = (values: String[] | String) => {
    setSpecialistIds(values)
  }

  // jxs status item ticket
  const returnJsxElementStatusTicket = (
    numberPayment: number | null | undefined
  ) => {
    if (numberPayment === 1) {
      return (
        <Tooltip
          label={t('statusTicket.1')}
          aria-label={t('statusTicket.1')}
          hasArrow
          placement="top-start"
        >
          <Box w="24px">
            <FiDollarSign
              color="#c71717f4"
              size={16}
              style={{ cursor: 'default' }}
            />
          </Box>
        </Tooltip>
      )
    }

    if (numberPayment === 2) {
      return (
        <Tooltip
          label={t('statusTicket.2')}
          aria-label={t('statusTicket.2')}
          hasArrow
          placement="top-start"
        >
          <Box w="24px">
            <FiDollarSign
              color="#56c20ff4"
              size={16}
              style={{ cursor: 'default' }}
            />
          </Box>
        </Tooltip>
      )
    }

    if (numberPayment === 3) {
      return (
        <Tooltip
          label={t('statusTicket.3')}
          aria-label={t('statusTicket.3')}
          hasArrow
          placement="top-start"
        >
          <Box w="24px">
            <FiDollarSign
              color="#fd7011f0"
              size={16}
              style={{ cursor: 'default' }}
            />
          </Box>
        </Tooltip>
      )
    }

    if (numberPayment === 4) {
      return (
        <Tooltip
          label={t('statusTicket.4')}
          aria-label={t('statusTicket.4')}
          hasArrow
          placement="top-start"
        >
          <Box w="24px">
            <FiDollarSign
              color="#192facdc"
              size={16}
              style={{ cursor: 'default' }}
            />
          </Box>
        </Tooltip>
      )
    }

    if (numberPayment === 5) {
      return (
        <Tooltip
          label={t('statusTicket.5')}
          aria-label={t('statusTicket.5')}
          hasArrow
          placement="top-start"
        >
          <Box w="24px">
            <FiDollarSign
              color="#121214"
              size={16}
              style={{ cursor: 'default' }}
            />
          </Box>
        </Tooltip>
      )
    }

    if (numberPayment === null) {
      return (
        <Box w="24px">
          <Text>---</Text>
        </Box>
      )
    }

    return (
      <Tooltip
        label={t('statusTicket.null')}
        aria-label={t('statusTicket.null')}
        hasArrow
        placement="top-start"
      >
        <Box w="24px">
          <FiDollarSign
            color="#9699B0"
            size={16}
            style={{ cursor: 'default' }}
          />
        </Box>
      </Tooltip>
    )
  }

  // jxs status receive
  const returnJsxElementStatusReceive = (
    numberPayment: number | null | undefined
  ) => {
    if (numberPayment === 2) {
      return (
        <Tooltip
          label={t('resultStatus.2')}
          aria-label={t('resultStatus.2')}
          hasArrow
          placement="top-start"
        >
          <Box>
            <Text color="#56c20ff4" fontSize="xs" style={{ cursor: 'default' }}>
              {t('resultStatus.2')}
            </Text>
          </Box>
        </Tooltip>
      )
    }

    if (numberPayment === 3) {
      return (
        <Tooltip
          label={t('resultStatus.3')}
          aria-label={t('resultStatus.3')}
          hasArrow
          placement="top-start"
        >
          <Box>
            <Text color="#c71717f4" fontSize="xs" style={{ cursor: 'default' }}>
              {t('resultStatus.3')}
            </Text>
          </Box>
        </Tooltip>
      )
    }

    return (
      <Tooltip
        label={t('resultStatus.null')}
        aria-label={t('resultStatus.null')}
        hasArrow
        placement="top-start"
      >
        <Box>
          <Text color="#9699B0" fontSize="xs" style={{ cursor: 'default' }}>
            {t('resultStatus.null')}
          </Text>
        </Box>
      </Tooltip>
    )
  }

  return (
    <>
      <LayoutBlank
        title={t('title')}
        placeholder={t('search')}
        capturaChange={(event) => SearchInputCallbackValue(event)}
        noRenderIsActive
        isHaveFilter
        nameFilter="TaxControl"
      >
        <Stack w="100%" spacing="4" direction={['row', 'column']}>
          <Table variant="striped" colorScheme="blue" size="sm">
            <Thead>
              <Tr>
                <Th fontSize="xs">{t('t1')}</Th>
                <Th fontSize="xs">{t('t2')}</Th>
                {/* <Th fontSize="xs">{t('t3')}</Th> */}
                {specialistIdsTaxControl?.length === 1 ? null : (
                  <Th fontSize="xs">{t('t4')}</Th>
                )}
                <Th fontSize="xs">{t('t5')}</Th>
                <Th fontSize="xs">{t('t6')}</Th>
                <Th textAlign="center" fontSize="xs">
                  {t('t7')}
                </Th>
                <Th textAlign="center" fontSize="xs">
                  {t('t8')}
                </Th>
                <Th fontSize="xs">{t('t9')}</Th>
                <Th fontSize="xs">{t('t10')}</Th>
                <Th fontSize="xs">{t('t11')}</Th>
              </Tr>
            </Thead>
            <Tbody>
              {getBillsToReceiveData?.isLoading ? (
                <>
                  {skeletonRepeat.map((skeleton) => (
                    <Tr key={skeleton}>
                      <Td>
                        <Skeleton height="20px" />
                      </Td>
                      <Td>
                        <Skeleton height="20px" />
                      </Td>
                      <Td>
                        <Skeleton height="20px" />
                      </Td>
                      <Td>
                        <Skeleton height="20px" />
                      </Td>
                      <Td>
                        <Skeleton height="20px" />
                      </Td>
                      <Td>
                        <Skeleton height="20px" />
                      </Td>
                      <Td>
                        <Skeleton height="20px" />
                      </Td>
                      <Td>
                        <Skeleton height="20px" />
                      </Td>
                      <Td>
                        <Skeleton height="20px" />
                      </Td>
                      <Td>
                        <Skeleton height="20px" />
                      </Td>
                      <Td>
                        <Skeleton height="20px" />
                      </Td>
                    </Tr>
                  ))}
                </>
              ) : (
                <>
                  {getBillsToReceiveData?.data?.taxControl?.map(
                    (con: Control) => (
                      <Tr key={con?.id}>
                        <Td w="150px">
                          <Text>{`${format(
                            new Date(
                              Number(con?.created_at?.substring(0, 4)),
                              Number(con?.created_at?.substring(5, 7)) - 1,
                              Number(con?.created_at?.substring(8, 10))
                            ),
                            'dd/MM/yyyy'
                          )}`}</Text>
                        </Td>
                        <Td>
                          {con?.patients_social_name?.map((name) => (
                            <Tooltip
                              label={name}
                              aria-label={name}
                              hasArrow
                              placement="top-start"
                            >
                              <Heading
                                fontSize="xs"
                                fontWeight="hairline"
                                noOfLines={1}
                                _hover={{
                                  cursor: 'default'
                                }}
                              >
                                {name}
                              </Heading>
                            </Tooltip>
                          ))}
                        </Td>
                        <Td>
                          {con?.specialists_social_name?.map((name) => (
                            <Tooltip
                              label={name}
                              aria-label={name}
                              hasArrow
                              placement="top-start"
                            >
                              <Heading
                                fontSize="xs"
                                fontWeight="hairline"
                                noOfLines={1}
                                _hover={{
                                  cursor: 'default'
                                }}
                              >
                                {name}
                              </Heading>
                            </Tooltip>
                          ))}
                        </Td>
                        <Td>
                          <Text fontSize="sm" fontWeight="hairline">
                            {con?.ticket_number || '---'}
                          </Text>
                        </Td>
                        <Td textAlign="right">
                          <Text fontSize="sm" fontWeight="hairline">
                            {formatBrOrDollarDefault(
                              con?.paid_value.toFixed(2) || '0.00'
                            ) || '---'}
                          </Text>
                        </Td>
                        {con?.generate_invoice === 1 && (
                          <Td textAlign="center">Sim</Td>
                        )}
                        {con?.generate_invoice === 2 && (
                          <Td textAlign="center">Não</Td>
                        )}
                        {!con?.generate_invoice && (
                          <Td textAlign="center">---</Td>
                        )}
                        <Td textAlign="right">
                          <Text fontSize="sm" fontWeight="hairline">
                            {formatBrOrDollarDefault(
                              (con?.total_value_invoice).toFixed(2) || '0.00'
                            ) || '---'}
                          </Text>
                        </Td>
                        <Td>
                          <Stack
                            direction="row"
                            alignItems="center"
                            justifyContent="flex-start"
                          >
                            {returnJsxElementStatusTicket(con?.status_payment)}
                            {!con.status_payment && (
                              <Td>
                                <Box>
                                  <Text fontSize="xs">---</Text>
                                </Box>
                              </Td>
                            )}
                            {returnJsxElementStatusReceive(con?.result_status)}
                          </Stack>
                        </Td>
                        <Td>
                          {con?.invoices?.map((inv) => (
                            <Tooltip
                              key={inv?.id || inv?.identifier}
                              label={inv?.tax_unit?.name}
                              aria-label={inv?.tax_unit?.name}
                            >
                              <Text noOfLines={1}>{inv?.identifier}</Text>
                            </Tooltip>
                          ))}
                        </Td>
                        <Td>
                          <Link
                            to={{
                              pathname: `/financial/tax/control/${con.id}`,
                              state: con
                            }}
                          >
                            <Tooltip
                              label="Editar"
                              aria-label="editar"
                              hasArrow
                              placement="top-start"
                            >
                              <Box w="24px">
                                <FiEdit
                                  color="#9699B0"
                                  size={16}
                                  style={{ cursor: 'pointer' }}
                                />
                              </Box>
                            </Tooltip>
                          </Link>
                        </Td>
                      </Tr>
                    )
                  )}
                </>
              )}
            </Tbody>
          </Table>
          {getBillsToReceiveData.data?.totalCountOfPage === 0 &&
            !getBillsToReceiveData.isLoading && <NotFound />}
          {!(
            getBillsToReceiveData.data?.totalCountOfPage === 0 &&
            !getBillsToReceiveData.isLoading
          ) && (
            <Pagination
              totalCountOfRegisters={
                getBillsToReceiveData.data?.totalCountOfPage || 0
              }
              currentPage={currentPage}
              onPageChange={setCurrentPage}
            />
          )}
        </Stack>
      </LayoutBlank>
      <ModalArrivedTime
        title={t('filters.title')}
        isOpen={isOpenTaxControl}
        onClose={handlerCloseModalTaxControl}
        onUpdate={() => {}}
        notRenderFooter
      >
        <Stack w="100%" p="4" dir="column" h="450px">
          <Center>
            <Text fontWeight="bold" fontSize="lg">
              {t('filters.selects')}
            </Text>
          </Center>
          <Flex w="100%" justifyContent="flex-start">
            <MultiSelectMenuFilter
              label="Especialistas"
              options={services.specialists.map((specialist) => {
                return {
                  id: String(specialist?.user?.id),
                  name: specialist?.social_name || specialist.user.name
                }
              })}
              backChange={(values) => {
                getFiltersSpecialist(values)
              }}
              render={() => {
                const specialistsRender: any = specialistIds
                return specialistsRender
              }}
            />

            <MultiSelectMenuFilter
              label="Status de geração"
              options={statusOptionsDefault.map((itemStatus) => {
                return {
                  id: String(itemStatus.value),
                  name: itemStatus.label
                }
              })}
              backChange={(values) => {
                setStatus(values)
              }}
              render={() => {
                const statusRender: any = status
                return statusRender
              }}
            />

            <MultiSelectMenuFilter
              label="Status de Pagamento"
              options={statusOptionsDefaultPayment.map((itemStatus) => {
                return {
                  id: String(itemStatus.value),
                  name: itemStatus.label
                }
              })}
              backChange={(values) => {
                setStatusPayment(values)
              }}
              render={() => {
                const statusRender: any = statusPayment
                return statusRender
              }}
            />
          </Flex>
          <Center>
            <Text fontWeight="bold" fontSize="lg">
              {t('filters.rangeDate')}
            </Text>
          </Center>
          <Flex w="100%" justifyContent="space-between" gap={2}>
            <Input
              name="dateInitial"
              type="date"
              isRequired
              label={t('filters.dateInitial')}
              value={startAt.substring(0, 10)}
              onChange={(event) => setStartAt(`${event.target.value}T00:00`)}
            />
            <Input
              name="dateFinal"
              type="date"
              isRequired
              label={t('filters.dateFinal')}
              value={endAt.substring(0, 10)}
              onChange={(event) => setEndAt(`${event.target.value}T23:59`)}
            />
          </Flex>
          <Flex
            position="absolute"
            bottom="2"
            right="2"
            justifyContent="flex-end"
            alignItems="flex-end"
            flexDir="row"
          >
            <Button
              variant="outline"
              mx="2"
              onClick={() => handlerRemoveFiltersTaxControl()}
            >
              Limpar filtro
            </Button>
            <Button
              bg="blue.300"
              color="white"
              onClick={() => {
                const filters = {
                  startAt,
                  endAt,
                  specialistIds,
                  status,
                  statusPayment
                }

                handlerApplyFiltersTaxControl(filters)
              }}
            >
              Aplicar filtro
            </Button>
          </Flex>
        </Stack>
      </ModalArrivedTime>
    </>
  )
}

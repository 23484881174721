/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Button,
  Center,
  Flex,
  Heading,
  SimpleGrid,
  Skeleton,
  Stack,
  Table,
  Tag,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
  useToast
} from '@chakra-ui/react'
import { addMonths, format } from 'date-fns'
import React, { FC, useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { AiFillLock, AiFillUnlock } from 'react-icons/ai'
import { FiDollarSign, FiEdit } from 'react-icons/fi'
import { Link } from 'react-router-dom'
import { Input } from '../../components/Form/Input'
import { Select } from '../../components/Form/Select'
import { TextFieldControlled } from '../../components/Form/TextFieldControlled'
import { ModalArrivedTime } from '../../components/Modal/confirmArrivedTime'
import { NotFound } from '../../components/molecules/NotFound'
import { MultiSelectMenuFilter } from '../../components/MultiSelectFilters'
import {
  getBillsToReceive,
  useBillsToReceive
} from '../../hooks/billsToReceive/useBillsToReceive'
import { useModal } from '../../hooks/useModal'
import { LayoutBlank } from '../../layouts/blank'
import { formatBrOrDollarDefault } from '../../utils/fns/formatBrOrDollarDefault'
import { useService } from './service'

// debounce
let timer: any = null

export const BillsToReceive: FC = () => {
  const [t] = useTranslation('pageBillsToReceive')
  const services = useService()
  const toast = useToast()
  const {
    endAtBillsToReceive,
    startAtBillsToReceive,
    statusParamReceived,
    defaultSearchBillsToReceive,
    setDefaultSearchBillsToReceive,
    specialistIdsReceived,
    handlerApplyFiltersBillsToReceive,
    numberTicketReceived,
    numberGuideReceived,
    isHaveTicketReceived,
    setTabIndexClient,
    blockGuideReceived,
    statusPaymentReceived
  } = useModal()
  const [currentPage, setCurrentPage] = useState(1)
  const skeletonRepeat = [1, 2, 3, 4, 5, 6, 7]
  const statusOptionsDefault = [
    { value: 1, label: `${t('status.1')}` },
    { value: 2, label: `${t('status.2')}` },
    { value: 3, label: `${t('status.3')}` },
    { value: 4, label: `${t('status.4')}` },
    { value: 5, label: `${t('status.5')}` },
    { value: 6, label: `${t('status.6')}` },
    { value: 7, label: `${t('status.7')}` }
  ]
  const statusOptionsDefaultPayment = [
    { value: 1, label: `${t('statusTicket.1')}` },
    { value: 2, label: `${t('statusTicket.2')}` },
    { value: 3, label: `${t('statusTicket.3')}` },
    { value: 4, label: `${t('statusTicket.4')}` },
    { value: 5, label: `${t('statusTicket.5')}` }
  ]
  const optionsBlock = [
    { value: '1', label: `${t('block')}` },
    { value: '0', label: `${t('noBlock')}` }
  ]
  const optionsTicket = [
    { value: '1', label: `${t('yes')}` },
    { value: '0', label: `${t('no')}` }
  ]
  // filters local
  const [startAt, setStartAt] = useState(startAtBillsToReceive)
  const [patientName, setPatientName] = useState(defaultSearchBillsToReceive)
  const [endAt, setEndAt] = useState(endAtBillsToReceive)
  const [specialistIds, setSpecialistIds] = useState<any>(specialistIdsReceived)
  const [status, setStatus] = useState<any>(statusParamReceived)
  const [numberTicket, setNumberTicket] = useState(numberTicketReceived)
  const [numberGuide, setNumberGuide] = useState(numberGuideReceived)
  const [isHaveTicket, setIsHaveTicket] = useState(isHaveTicketReceived)
  const [blockedGuide, setBlockedGuide] = useState(blockGuideReceived)
  const [statusPayment, setStatusPayment] = useState<any>(statusPaymentReceived)

  const dateDefault =
    new Date(
      Number(startAt?.substring(0, 4)),
      Number(startAt?.substring(5, 7)) - 1,
      Number(startAt?.substring(8, 10))
    ) || new Date()

  const maxDate = addMonths(dateDefault, 13)

  const getBillsToReceiveData = useBillsToReceive(
    currentPage,
    getBillsToReceive(
      currentPage,
      defaultSearchBillsToReceive,
      startAtBillsToReceive,
      endAtBillsToReceive,
      specialistIdsReceived,
      statusParamReceived,
      statusPaymentReceived,
      numberTicketReceived,
      numberGuideReceived,
      blockGuideReceived,
      isHaveTicketReceived
    ),
    defaultSearchBillsToReceive,
    startAtBillsToReceive,
    endAtBillsToReceive,
    specialistIdsReceived,
    statusParamReceived,
    statusPaymentReceived,
    numberTicketReceived,
    numberGuideReceived,
    blockGuideReceived,
    isHaveTicketReceived
  )

  const SearchInputCallbackValue = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      const { value } = event.currentTarget

      clearTimeout(timer)

      timer = setTimeout(() => {
        setCurrentPage(1)
        setDefaultSearchBillsToReceive(value)
      }, 500)
    },
    []
  )

  // get filters specialist
  const getFiltersSpecialist = (values: String[] | String) => {
    setSpecialistIds(values)
  }

  // return hours format
  const hoursFormat = (myDate: string) => {
    if (myDate === null) {
      return ''
    }

    const formatDate = myDate.substring(11, 16)
    return formatDate
  }

  // jxs status item ticket
  const returnJsxElementStatusTicket = (
    statusPaymentTicket: number | null | undefined
  ) => {
    if (statusPaymentTicket === 1) {
      return (
        <Tooltip
          label={t('statusTicket.1')}
          aria-label={t('statusTicket.1')}
          hasArrow
          placement="top-start"
        >
          <Box w="24px">
            <FiDollarSign
              color="#c71717f4"
              size={16}
              style={{ cursor: 'default' }}
            />
          </Box>
        </Tooltip>
      )
    }

    if (statusPaymentTicket === 2) {
      return (
        <Tooltip
          label={t('statusTicket.2')}
          aria-label={t('statusTicket.2')}
          hasArrow
          placement="top-start"
        >
          <Box w="24px">
            <FiDollarSign
              color="#56c20ff4"
              size={16}
              style={{ cursor: 'default' }}
            />
          </Box>
        </Tooltip>
      )
    }

    if (statusPaymentTicket === 3) {
      return (
        <Tooltip
          label={t('statusTicket.3')}
          aria-label={t('statusTicket.3')}
          hasArrow
          placement="top-start"
        >
          <Box w="24px">
            <FiDollarSign
              color="#fd7011f0"
              size={16}
              style={{ cursor: 'default' }}
            />
          </Box>
        </Tooltip>
      )
    }

    if (statusPaymentTicket === 4) {
      return (
        <Tooltip
          label={t('statusTicket.4')}
          aria-label={t('statusTicket.4')}
          hasArrow
          placement="top-start"
        >
          <Box w="24px">
            <FiDollarSign
              color="#192facdc"
              size={16}
              style={{ cursor: 'default' }}
            />
          </Box>
        </Tooltip>
      )
    }

    if (statusPaymentTicket === 5) {
      return (
        <Tooltip
          label={t('statusTicket.5')}
          aria-label={t('statusTicket.5')}
          hasArrow
          placement="top-start"
        >
          <Box w="24px">
            <FiDollarSign
              color="#121214"
              size={16}
              style={{ cursor: 'default' }}
            />
          </Box>
        </Tooltip>
      )
    }

    if (statusPaymentTicket === null) {
      return (
        <Box w="24px">
          <Text>---</Text>
        </Box>
      )
    }

    return (
      <Tooltip
        label={t('statusTicket.null')}
        aria-label={t('statusTicket.null')}
        hasArrow
        placement="top-start"
      >
        <Box w="24px">
          <FiDollarSign
            color="#9699B0"
            size={16}
            style={{ cursor: 'default' }}
          />
        </Box>
      </Tooltip>
    )
  }

  const verifyFilterApply = () => {
    let isDifferent = false

    if (specialistIds !== specialistIdsReceived) {
      isDifferent = true
    }

    if (status !== statusParamReceived) {
      isDifferent = true
    }

    if (statusPayment !== statusPaymentReceived) {
      isDifferent = true
    }

    if (patientName !== defaultSearchBillsToReceive) {
      isDifferent = true
    }

    if (startAt !== startAtBillsToReceive) {
      isDifferent = true
    }

    if (endAt !== endAtBillsToReceive) {
      isDifferent = true
    }

    if (numberGuide !== numberGuideReceived) {
      isDifferent = true
    }

    if (numberTicket !== numberTicketReceived) {
      isDifferent = true
    }

    if (isHaveTicket !== isHaveTicketReceived) {
      isDifferent = true
    }

    if (blockedGuide !== blockGuideReceived) {
      isDifferent = true
    }

    return isDifferent
  }

  const isButtonFilters = verifyFilterApply()

  return (
    <>
      <LayoutBlank
        title={t('title')}
        placeholder={t('search')}
        nameFilter="BillsToReceive"
        defaultSearch={defaultSearchBillsToReceive}
        capturaChange={(event) => SearchInputCallbackValue(event)}
        noRenderIsActive
        isSearched={false}
      >
        <Stack w="100%" spacing="4" direction={['row', 'column']}>
          <Stack w="100%" p="4" dir="column">
            <SimpleGrid w="100%" justifyContent="space-between" gap={2}>
              <Flex justifyContent="space-between" gap={2}>
                <Box w="150px">
                  <Input
                    name="dateInitial"
                    type="date"
                    isRequired
                    value={startAt.substring(0, 10)}
                    onChange={(event) => {
                      if (event.target.value !== '') {
                        setStartAt(`${event.target.value}T00:00`)

                        setNumberGuide('')
                        setNumberTicket('')
                        setEndAt('')
                      }
                    }}
                  />
                </Box>
                <Box w="150px">
                  <Input
                    name="dateFinal"
                    min={startAt.substring(0, 10)}
                    max={format(maxDate, 'yyyy-MM-dd')}
                    isDisabled={startAt === ''}
                    type="date"
                    isRequired
                    value={endAt.substring(0, 10)}
                    onChange={(event) => {
                      if (event.target.value !== '') {
                        setEndAt(`${event.target.value}T23:59`)

                        setNumberGuide('')
                        setNumberTicket('')
                      }
                    }}
                  />
                </Box>
                <MultiSelectMenuFilter
                  label="Especialistas"
                  options={services.specialists.map((specialist) => {
                    return {
                      id: String(specialist?.user?.id),
                      name: specialist?.social_name || specialist.user.name
                    }
                  })}
                  backChange={(values) => {
                    getFiltersSpecialist(values)
                  }}
                  render={() => {
                    const specialistsRender: any = specialistIds
                    return specialistsRender
                  }}
                />

                <MultiSelectMenuFilter
                  label="Status"
                  options={statusOptionsDefault.map((itemStatus) => {
                    return {
                      id: String(itemStatus.value),
                      name: itemStatus.label
                    }
                  })}
                  backChange={(values) => {
                    setStatus(values)
                  }}
                  render={() => {
                    const statusRender: any = status
                    return statusRender
                  }}
                />
                <MultiSelectMenuFilter
                  label="Status de pagamento"
                  options={statusOptionsDefaultPayment.map((itemStatus) => {
                    return {
                      id: String(itemStatus.value),
                      name: itemStatus.label
                    }
                  })}
                  backChange={(values) => {
                    setStatusPayment(values)
                  }}
                  render={() => {
                    const statusRender: any = statusPaymentReceived
                    return statusRender
                  }}
                />
                <Box w="180px">
                  <Select
                    name="blocked"
                    options={optionsBlock}
                    placeholder="Status de bloqueio"
                    onChange={(e) => setBlockedGuide(e.target.value)}
                    value={blockedGuide}
                  />
                </Box>
                <Box w="150px">
                  <Select
                    name="ticket"
                    options={optionsTicket}
                    placeholder="Existe ticket?"
                    onChange={(e) => setIsHaveTicket(e.target.value)}
                    value={isHaveTicket}
                  />
                </Box>
              </Flex>
              <Flex justifyContent="flex-start" gap={2}>
                <Flex justifyContent="space-between" gap={2}>
                  <Box w="160px">
                    <Input
                      name="numberTicket"
                      placeholder="Número do ticket"
                      value={numberTicket}
                      onChange={(event) => {
                        setNumberTicket(event.target.value)

                        if (event.target.value !== '') {
                          setStartAt('')
                          setEndAt('')
                          setNumberGuide('')
                        }
                      }}
                    />
                  </Box>
                  <Box w="150px">
                    <Input
                      name="numberGuide"
                      placeholder="Número do guia"
                      value={numberGuide}
                      onChange={(event) => {
                        setNumberGuide(event.target.value)

                        if (event.target.value !== '') {
                          setStartAt('')
                          setEndAt('')
                          setNumberTicket('')
                        }
                      }}
                    />
                  </Box>
                  <Flex mx="8px" align="baseline" justify="baseline">
                    <TextFieldControlled
                      name="patient_name"
                      placeholder="Nome do paciente"
                      textTransform="uppercase"
                      textAlign="left"
                      value={patientName}
                      onChange={(event) => setPatientName(event.target.value)}
                    />
                  </Flex>
                </Flex>
                {isButtonFilters && (
                  <Flex justifyContent="flex-end" flexDir="row">
                    <Button
                      bg="blue.300"
                      color="white"
                      onClick={() => {
                        let throwError = false

                        if (numberTicket === '' && numberGuide === '') {
                          if (startAt === '') {
                            toast({
                              title: 'Data inicial obrigatória',
                              position: 'top-right',
                              status: 'error'
                            })

                            throwError = true
                          }

                          if (endAt === '') {
                            toast({
                              title: 'Data final obrigatória',
                              position: 'top-right',
                              status: 'error'
                            })

                            throwError = true
                          }
                        }

                        if (throwError) {
                          toast({
                            title: 'Revise os valores de filtro.',
                            position: 'top-right',
                            status: 'error'
                          })

                          return
                        }

                        const filters = {
                          startAt,
                          endAt,
                          specialistIds,
                          status,
                          statusPayment,
                          numberGuide,
                          numberTicket,
                          blockGuide: blockedGuide,
                          isHaveTicket,
                          patientName
                        }

                        handlerApplyFiltersBillsToReceive(filters)
                      }}
                    >
                      Aplicar filtro
                    </Button>
                  </Flex>
                )}
              </Flex>
            </SimpleGrid>
          </Stack>
          <Table variant="striped" colorScheme="blue" size="sm">
            <Thead>
              <Tr>
                <Th fontSize="xs">{t('t1')}</Th>
                <Th fontSize="xs">{t('t2')}</Th>
                <Th fontSize="xs">{t('t3')}</Th>
                {specialistIdsReceived.length === 1 ? null : (
                  <Th fontSize="xs">{t('t4')}</Th>
                )}
                <Th fontSize="xs">{t('t5')}</Th>
                <Th fontSize="xs">{t('t6')}</Th>
                <Th fontSize="xs">{t('t7')}</Th>
                <Th fontSize="xs">{t('t8')}</Th>
                <Th fontSize="xs">{t('t9')}</Th>
                <Th fontSize="xs">{t('t10')}</Th>
                <Th fontSize="xs">{t('t11')}</Th>
              </Tr>
            </Thead>
            <Tbody>
              {getBillsToReceiveData?.isLoading ? (
                <>
                  {skeletonRepeat.map((skeleton) => (
                    <Tr key={skeleton}>
                      <Td>
                        <Skeleton height="20px" />
                      </Td>
                      <Td>
                        <Skeleton height="20px" />
                      </Td>
                      <Td>
                        <Skeleton height="20px" />
                      </Td>
                      <Td>
                        <Skeleton height="20px" />
                      </Td>
                      <Td>
                        <Skeleton height="20px" />
                      </Td>
                      <Td>
                        <Skeleton height="20px" />
                      </Td>
                      <Td>
                        <Skeleton height="20px" />
                      </Td>
                      <Td>
                        <Skeleton height="20px" />
                      </Td>
                      <Td>
                        <Skeleton height="20px" />
                      </Td>
                      <Td>
                        <Skeleton height="20px" />
                      </Td>
                      <Td>
                        <Skeleton height="20px" />
                      </Td>
                    </Tr>
                  ))}
                </>
              ) : (
                <>
                  {getBillsToReceiveData?.data?.billsToReceive.map(
                    (receiveItem) => (
                      <Tr
                        key={
                          receiveItem?.id || receiveItem.scheduled_arrival_time
                        }
                      >
                        <Td w="150px">
                          <Text>{`${format(
                            new Date(receiveItem.scheduled_arrival_time),
                            'dd/MM/yyyy'
                          )} ${hoursFormat(
                            receiveItem.scheduled_arrival_time
                          )}`}</Text>
                        </Td>
                        <Td w="150px">
                          {receiveItem.status === 1 && (
                            <Text fontSize="small">{t('status.1')}</Text>
                          )}
                          {receiveItem.status === 2 && (
                            <Text fontSize="small">{t('status.2')}</Text>
                          )}
                          {receiveItem.status === 3 && (
                            <Text fontSize="small">{t('status.3')}</Text>
                          )}
                          {receiveItem.status === 4 && (
                            <Text fontSize="small">{t('status.4')}</Text>
                          )}
                          {receiveItem.status === 5 && (
                            <Text fontSize="small">{t('status.5')}</Text>
                          )}
                          {receiveItem.status === 6 && (
                            <Text fontSize="small">{t('status.6')}</Text>
                          )}
                          {receiveItem.status === 7 && (
                            <Text fontSize="small">{t('status.7')}</Text>
                          )}
                        </Td>
                        <Td w="280px">
                          <Tooltip
                            label={
                              receiveItem?.patient_social_name ||
                              receiveItem?.patient_name ||
                              ''
                            }
                            aria-label={
                              receiveItem?.patient_social_name ||
                              receiveItem?.patient_name ||
                              ''
                            }
                            hasArrow
                            placement="top-start"
                          >
                            <Link
                              to={{
                                pathname: `/clients/update/${receiveItem.patient_id}`
                              }}
                              onClick={() => {
                                setTabIndexClient(1)
                              }}
                            >
                              <Text
                                noOfLines={1}
                                fontSize="xs"
                                _hover={{
                                  textDecoration: 'underline'
                                }}
                              >
                                {receiveItem?.patient_name ||
                                  receiveItem?.patient_social_name}
                              </Text>
                            </Link>
                          </Tooltip>
                        </Td>
                        {specialistIdsReceived?.length === 1 ? null : (
                          <Td>
                            <Tooltip
                              label={receiveItem?.specialist_name}
                              aria-label={receiveItem?.specialist_name}
                              hasArrow
                              placement="top-start"
                            >
                              <Heading
                                fontSize="xs"
                                fontWeight="hairline"
                                noOfLines={1}
                                _hover={{
                                  cursor: 'default'
                                }}
                              >
                                {receiveItem?.specialist_name}
                              </Heading>
                            </Tooltip>
                          </Td>
                        )}
                        <Td>
                          <Text fontSize="sm" fontWeight="hairline">
                            {receiveItem?.ticket_number || '---'}
                          </Text>
                        </Td>
                        <Td textAlign="right">
                          <Text fontSize="sm" fontWeight="hairline">
                            {formatBrOrDollarDefault(
                              receiveItem?.ticket?.total_value.toFixed(2) ||
                                '0.00'
                            ) || '---'}
                          </Text>
                        </Td>
                        <Td textAlign="right">
                          <Text fontSize="sm" fontWeight="hairline">
                            {formatBrOrDollarDefault(
                              receiveItem?.ticket?.paid_value.toFixed(2) ||
                                '0.00'
                            ) || '---'}
                          </Text>
                        </Td>
                        <Td textAlign="right">
                          {receiveItem?.ticket_number ? (
                            <Text fontSize="sm" fontWeight="hairline">
                              {formatBrOrDollarDefault(
                                (
                                  receiveItem?.ticket?.total_value -
                                  receiveItem.ticket?.paid_value
                                ).toFixed(2) || '0.00'
                              ) || '0,00'}
                            </Text>
                          ) : (
                            <Text fontSize="sm" fontWeight="hairline">
                              {formatBrOrDollarDefault(
                                (
                                  receiveItem?.ticket?.total_value -
                                  receiveItem.ticket?.paid_value
                                ).toFixed(2) || '0.00'
                              ) || '---'}
                            </Text>
                          )}
                        </Td>
                        <Td>
                          <Stack direction="row">
                            {returnJsxElementStatusTicket(
                              receiveItem?.ticket_status_payment
                            )}
                            {receiveItem?.block_guide ? (
                              <Tooltip
                                label={t('block')}
                                aria-label={t('block')}
                                hasArrow
                                placement="top-start"
                              >
                                <Box>
                                  <AiFillLock size={20} color="red" />
                                </Box>
                              </Tooltip>
                            ) : (
                              <Tooltip
                                label={t('noBlock')}
                                aria-label={t('noBlock')}
                                hasArrow
                                placement="top-start"
                              >
                                <Box>
                                  <AiFillUnlock size={20} color="green" />
                                </Box>
                              </Tooltip>
                            )}
                          </Stack>
                        </Td>
                        {receiveItem.ticket?.result_status === 2 && (
                          <Td>
                            <Tag bg="#56c20ff4" color="white">
                              <Text fontSize="xs" fontWeight="bold">
                                {t('resultStatus.2')}
                              </Text>
                            </Tag>
                          </Td>
                        )}
                        {receiveItem.ticket?.result_status === 3 && (
                          <Td>
                            <Tag bg="#c71717f4" color="white">
                              <Text fontSize="xs" fontWeight="bold">
                                {t('resultStatus.3')}
                              </Text>
                            </Tag>
                          </Td>
                        )}
                        {!receiveItem.ticket && (
                          <Td w="150px">
                            <Text fontSize="xs" fontWeight="bold">
                              ---
                            </Text>
                          </Td>
                        )}
                        {receiveItem.ticket &&
                          !receiveItem.ticket?.result_status &&
                          receiveItem.ticket_status_payment !== 5 && (
                            <Td w="150px">
                              <Tag bg="#9699B0" size="sm" color="white">
                                <Text fontSize="xs" fontWeight="bold">
                                  {t('resultStatus.null')}
                                </Text>
                              </Tag>
                            </Td>
                          )}
                        {!receiveItem.ticket?.result_status &&
                          receiveItem.ticket_status_payment === 5 && (
                            <Td w="150px">
                              <Text fontSize="xs" fontWeight="bold">
                                ---
                              </Text>
                            </Td>
                          )}
                        <Td>
                          <Link
                            to={{
                              pathname: `/financial/billy-to-receive/conference/${receiveItem.id}`
                            }}
                          >
                            <Tooltip
                              label="Editar"
                              aria-label="editar"
                              hasArrow
                              placement="top-start"
                            >
                              <Box w="24px">
                                <FiEdit
                                  color="#9699B0"
                                  size={16}
                                  style={{ cursor: 'pointer' }}
                                />
                              </Box>
                            </Tooltip>
                          </Link>
                        </Td>
                      </Tr>
                    )
                  )}
                </>
              )}
            </Tbody>
          </Table>
          {getBillsToReceiveData.data?.totalCountOfPage === 0 &&
            !getBillsToReceiveData.isLoading && <NotFound />}
        </Stack>
        <Flex w="100%" h="200px" />
      </LayoutBlank>
      <Flex w="100%" h="400px" />
    </>
  )
}

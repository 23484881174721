/* eslint-disable camelcase */
/* eslint-disable react-hooks/exhaustive-deps */
import { useToast } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useAuth } from '../../hooks/auth'
import { apiAuth } from '../../services/apiAuth'
import { queryClient } from '../../services/reactQuery'

export type ServicesData = {
  id: number
  name: string
  service_sessions: Array<{
    id: number
    duration: number
    time_to_be_there: number
    session_number: number
    service_id: number
  }>
}

export type SpotsData = {
  id: number
  name: string
  is_default: boolean
}

export type TagsData = {
  id: number
  name: string
}

export type ClientsData = {
  id: number
  name: string
}

export type SpecialistsData = {
  id: number
  social_name: string
  user: {
    id: number
    name: string
    email: string
    contact: string
  }
}

export const useService = () => {
  // Clinic Id Get decode hook
  const { clinicId } = useAuth()
  // hook alert and navigation
  const toast = useToast()
  const router = useHistory()

  const [services, setServices] = useState<ServicesData[]>([])
  const [isLoadingService, setIsLoadingService] = useState(false)
  const [spots, setSpots] = useState<SpotsData[]>([])
  const [isLoadingSpots, setIsLoadingSpots] = useState(false)
  const [clients, setClients] = useState<ClientsData[]>([])
  const [isLoadingClients, setIsLoadingClients] = useState(false)
  const [tags, setTags] = useState<TagsData[]>([])
  const [isLoadingSpecialists, setIsLoadingSpecialists] = useState(false)
  const [specialists, setSpecialists] = useState<SpecialistsData[]>([])
  const [isLoadingSpecialistsEdit, setIsLoadingSpecialistsEdit] =
    useState(false)
  const [specialistsEdit, setSpecialistsEdit] = useState<SpecialistsData[]>([])

  const getClients = async (search?: string) => {
    if (search !== undefined && search?.length <= 2) {
      return []
    }

    try {
      const { data } = await apiAuth.get(`clinics/patients/`, {
        params: {
          is_active: true,
          name__icontains: search || '',
          noPaginate: 1
        }
      })

      setClients(data.results)
      return data.results.map((client: any) => {
        return {
          value: client.id,
          label: client.name
        }
      })
    } catch (err: any) {
      console.log(err)
      return []
    }
  }

  // effect loading infos in pages

  useEffect(() => {
    // get infos by scheduling

    const getServices = () => {
      try {
        setIsLoadingService(true)
        apiAuth
          .get(`clinics/${clinicId}/services/`, {
            params: {
              is_active: true,
              noPaginate: 1,
              onlyFields: 'id,name,service_sessions'
            }
          })
          .then((response) => {
            setServices(response.data)
            setIsLoadingService(false)
          })
      } catch (err: any) {
        console.log(err)
        setIsLoadingService(false)
      }
    }

    const getSpecialists = () => {
      try {
        setIsLoadingSpecialists(true)
        apiAuth
          .get(`users/attendances/`, {
            params: {
              user__is_active: true,
              is_specialist: true,
              permission_type: '2'
            }
          })
          .then((response) => {
            setSpecialists(response.data.results)
            setIsLoadingSpecialists(false)
          })
      } catch (err: any) {
        console.log(err)
        setIsLoadingSpecialists(false)
      }
    }

    const getSpecialistsEdit = () => {
      try {
        setIsLoadingSpecialistsEdit(true)
        apiAuth
          .get(`users/attendances/`, {
            params: {
              user__is_active: true,
              is_specialist: true,
              permission_type: '3'
            }
          })
          .then((response) => {
            setSpecialistsEdit(response.data.results)
            setIsLoadingSpecialistsEdit(false)
          })
      } catch (err: any) {
        console.log(err)
        setIsLoadingSpecialistsEdit(false)
      }
    }

    const getAttendanceSpots = () => {
      try {
        setIsLoadingSpots(true)
        apiAuth
          .get(`clinics/${clinicId}/attendance-spots/`, {
            params: {
              is_active: true
            }
          })
          .then((response) => {
            setSpots(response.data.results)
            setIsLoadingSpots(false)
          })
      } catch (err: any) {
        console.log(err)
        setIsLoadingSpots(false)
      }
    }

    const getTags = () => {
      try {
        apiAuth
          .get(`clinics/${clinicId}/tags/`, {
            params: {
              is_active: true
            }
          })
          .then((response) => {
            setTags(response.data.results)
          })
      } catch (error: any) {
        console.log(error)
      }
    }

    getServices()
    getAttendanceSpots()
    getTags()
    getSpecialists()
    getSpecialistsEdit()

    return () => {}
  }, [])

  // update new client info
  const UpdateClients = (search?: string) => {
    getClients(search)
  }

  // create new attendance by scheduling
  const handlerCreateNewAttendance = async (payload: object) => {
    try {
      const { data } = await apiAuth.post('clinics/attendances/', payload)

      if (data.id !== undefined) {
        toast({
          title: 'Cadastro realizado com sucesso!',
          description: 'Estamos lhe redirecionando.',
          status: 'success',
          isClosable: true,
          duration: 3000,
          position: 'top'
        })

        queryClient?.invalidateQueries('scheduling')
        queryClient?.refetchQueries('scheduling')

        setTimeout(() => {
          router.push('/scheduling')
        }, 2000)
      }
    } catch (err: any) {
      if (err?.response?.data?.code[0] === 'E001') {
        toast({
          title: 'Atenção agendamento não permitido',
          description: `O horário de agendamento está bloqueado, selecione um horário válido`,
          status: 'error',
          isClosable: true,
          duration: 3000,
          position: 'top'
        })
      } else {
        toast({
          title: 'Aconteceu um error!',
          description: `${err.response?.data?.message || 'Error interno'}`,
          status: 'error',
          isClosable: true,
          duration: 3000,
          position: 'top'
        })
      }
    }
  }

  // create new attendance by scheduling
  const handlerUpdateAttendance = async (payload: object, id: string) => {
    try {
      const { data } = await apiAuth.put(`clinics/attendances/${id}/`, payload)

      if (data.id !== undefined) {
        toast({
          title: 'Atualização realizada com sucesso!',
          status: 'success',
          isClosable: true,
          duration: 3000,
          position: 'top'
        })

        queryClient?.invalidateQueries('scheduling')
        queryClient?.refetchQueries('scheduling')
        queryClient.setQueryData(['scheduling', { id: data.id }], data)
      }
    } catch (err: any) {
      if (err?.response?.data?.code[0] === 'E001') {
        toast({
          title: 'Atenção agendamento não permitido',
          description: `O horário de agendamento está bloqueado, selecione um horário válido`,
          status: 'error',
          isClosable: true,
          duration: 3000,
          position: 'top'
        })
      } else {
        toast({
          title: 'Aconteceu um error!',
          description: `${err.response?.data?.message || 'Error interno'}`,
          status: 'error',
          isClosable: true,
          duration: 3000,
          position: 'top'
        })
      }
    }
  }

  const handlerUpdateAttendanceRedirect = async (
    payload: object,
    id: string
  ) => {
    try {
      const { data } = await apiAuth.put(`clinics/attendances/${id}/`, payload)

      if (data.id !== undefined) {
        toast({
          title: 'Atualização realizada com sucesso!',
          description: 'Estamos lhe redirecionando.',
          status: 'success',
          isClosable: true,
          duration: 3000,
          position: 'top'
        })

        queryClient?.invalidateQueries('scheduling')
        queryClient?.refetchQueries('scheduling')
        queryClient.setQueryData(['scheduling', { id: data.id }], data)

        setTimeout(() => {
          router.push('/scheduling')
        }, 2000)
      }
    } catch (err: any) {
      toast({
        title: 'Aconteceu um error!',
        description: `${err.response?.data?.message}`,
        status: 'error',
        isClosable: true,
        duration: 3000,
        position: 'top-right'
      })
    }
  }

  return {
    services,
    spots,
    clients,
    tags,
    getClients,
    isLoadingSpecialistsEdit,
    specialistsEdit,
    isLoadingSpecialists,
    handlerCreateNewAttendance,
    handlerUpdateAttendanceRedirect,
    handlerUpdateAttendance,
    specialists,
    UpdateClients,
    isLoadingSpots,
    isLoadingService,
    isLoadingClients
  }
}

/* eslint-disable radix */
/* eslint-disable camelcase */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Button,
  Flex,
  Stack,
  Text,
  useBreakpointValue
} from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import FileSaver from 'file-saver'
import format from 'date-fns/format'

import { SubmitHandler, useForm } from 'react-hook-form'

import { useTranslation } from 'react-i18next'
import { apiAuth } from '../../services/apiAuth'

import { Header } from '../../components/Header'
import { CardContainer } from '../../components/molecules/CardContainer'
import { Sidebar } from '../../components/Sidebar'

import { Input } from '../../components/Form/Input'
import { SelectComponent } from '../../components/Form/SelectComponent'

type FormData = {
  is_active: boolean
  description: string
  is_special: boolean
}

type Data = {
  label: string
  value: string
}

type Report = {
  label: string
  value: string
}

type ReportData = Array<Report>

export function ReportsMarketing() {
  const [t] = useTranslation('pageReportsMarketing')
  const { register, handleSubmit, formState } = useForm<FormData>({})
  const isWideVersion = useBreakpointValue({
    base: false,
    lg: true
  })
  const optionsDefaultActions = [
    {
      value: 'clinics/patients/export-origins/',
      label: `${t('reports.1')}`
    }
  ]
  const [initialDate, setInitialDate] = useState('')
  const [finalDate, setFinalDate] = useState('')
  const [selectOption, setSelectOption] = useState<Report | null>(
    optionsDefaultActions[0]
  )
  const [isLoading, setIsLoading] = useState(false)

  const handleCreateRecipe: SubmitHandler<FormData> = async (values) => {
    setIsLoading(true)
    try {
      const { data } = await apiAuth.get(
        `${selectOption?.value}`,

        {
          params: {
            start_date: initialDate,
            finish_date: finalDate
          },
          responseType: 'blob'
        }
      )

      FileSaver(
        data,
        `${selectOption?.label}_${format(new Date(), 'dd/MM/yyyy')}.xlsx`
      )
    } catch (error: any) {
      console.log(error)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <Box overflow="hidden" position="relative">
      <Header>
        <Flex
          mx="auto"
          w="100%"
          direction="row"
          d="flex"
          my="6"
          align="center"
          justify="space-around"
        >
          <Box mx="auto">
            <Text fontSize="24px" fontWeight="600" height="100%">
              {t('create.title')}
            </Text>
          </Box>
        </Flex>
      </Header>
      <Sidebar />
      <Flex
        flex="1"
        mx="auto"
        as="form"
        ml={isWideVersion ? '75px' : '2'}
        mt="15px"
        mb="8px"
        mr="2"
        bg="white"
        h="cal(100vh - 60px)"
        onSubmit={handleSubmit(handleCreateRecipe)}
      >
        <Stack spacing="4" w="100%">
          <CardContainer title={t('create.container1.title')}>
            <Stack w="100%" spacing={6} direction="row">
              <Box w="320px">
                <Input
                  label="Data inicial"
                  value={initialDate}
                  onChange={(e) => setInitialDate(e.target.value)}
                  isRequired
                  name="inicialDate"
                  type="date"
                />
              </Box>
              <Box w="320px">
                <Input
                  value={finalDate}
                  onChange={(e) => setFinalDate(e.target.value)}
                  isRequired
                  label="Data final"
                  name="finishDate"
                  type="date"
                />
              </Box>
              <SelectComponent
                name="typeActionReport"
                options={optionsDefaultActions}
                value={selectOption}
                title="Tipo de relatório"
                placeholder={t('create.container1.placeholder')}
                onChange={(e: any) => setSelectOption(e)}
              />
            </Stack>
            <Flex direction="column" my="2">
              <Button
                bg="blue.300"
                color="white"
                type="submit"
                isLoading={isLoading}
              >
                Gerar relatório
              </Button>
            </Flex>
          </CardContainer>
          <Flex w="100%" h="300px" />
        </Stack>
      </Flex>
    </Box>
  )
}

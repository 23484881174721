/* eslint-disable prettier/prettier */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from 'react'
import {
  Box,
  Tooltip,
  Table,
  Thead,
  Tr,
  Th,
  Td,
  Tbody,
  Text,
  Skeleton,
  Heading,
  Flex,
  useDisclosure,
  useToast
} from '@chakra-ui/react'
import { FiEdit } from 'react-icons/fi'
import { AiFillDelete } from 'react-icons/ai'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import format from 'date-fns/format'
import {
  zonedTimeToUtc,
  utcToZonedTime,
  format as formatTimeZone
} from 'date-fns-tz'
import {
  BlockTimesData,
  getBlockTimes,
  useBlockTimes
} from '../../hooks/blockTimes/useBlockTimes'
import { useAuth } from '../../hooks/auth'
import { LayoutList } from '../../layouts/List'
import { NotFound } from '../../components/molecules/NotFound'
import { Pagination } from '../../components/PaginationNew'
import { formatCurrency } from '../../utils/fns/removeCaracters'
import { PermissionComponent } from '../../components/Permissions'
import { useModal } from '../../hooks/useModal'
import { ModalConfirmExclude } from '../../components/Modal/confirmExclude'
import { apiAuth } from '../../services/apiAuth'

// debounce
let timer: any = null

export function BlockTimes() {
  const { decodeToken } = useAuth()
  const toast = useToast()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { LocaleApp } = useModal()
  const [t] = useTranslation('pageBlockTimes')
  const skeletonRepeat = [1, 2, 3, 4, 5, 6, 7]
  // state
  const [searchState, setSearchState] = useState('')
  const [currentPage, setCurrentPage] = useState(1)
  const [idExclude, setIdExclude] = useState(0)

  const getBlockTimesData = useBlockTimes(
    currentPage,
    getBlockTimes(currentPage, '', '', ''),
    '',
    '',
    ''
  )

  const SearchInputCallbackValue = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      const { value } = event.currentTarget

      clearTimeout(timer)

      timer = setTimeout(() => {
        setCurrentPage(1)
        setSearchState(value)
      }, 500)
    },
    []
  )

  const ActiveCheckboxCallbackValue = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {},
    []
  )

  async function handleExcludedBlockById(id: number) {
    const { status } = await apiAuth.delete(`clinics/block-times/${id}/`)

    if (status === 204) {
      toast({
        position: 'top-right',
        status: 'success',
        duration: 3000,
        title: 'Documento excluído',
        isClosable: true,
        description: 'Bloqueio excluído com sucesso'
      })
    } else {
      toast({
        position: 'top-right',
        status: 'warning',
        duration: 3000,
        title: 'Ação não realizada!',
        isClosable: true,
        description: 'Não foi possível excluir bloqueio'
      })
    }
  }

  useEffect(() => {
    if (!isOpen) {
      getBlockTimesData?.remove()
      getBlockTimesData.refetch()
    }
  }, [isOpen])

  return (
    <>
      <LayoutList
        title={t('title')}
        urlNew="/settings/block-times/create"
        refetch={getBlockTimesData.refetch}
        capturaChange={(event: any) => SearchInputCallbackValue(event)}
        captureActiveChange={(event: any) => ActiveCheckboxCallbackValue(event)}
        placeholder={t('search')}
        isViewActive={false}
      >
        <Box>
          <Table variant="striped" colorScheme="blue" size="sm">
            <Thead>
              <Tr>
                <Th>{t('t1')}</Th>
                <Th>{t('t2')}</Th>
                <Th>{t('t3')}</Th>
                <Th>{t('t4')}</Th>
                <Th>{t('t5')}</Th>
                <Th>{t('t6')}</Th>
                <Th>{t('t7')}</Th>
              </Tr>
            </Thead>
            <Tbody>
              {getBlockTimesData.isLoading ? (
                <>
                  {skeletonRepeat.map((skeleton) => (
                    <Tr>
                      <Td>
                        <Skeleton height="20px" />
                      </Td>
                      <Td>
                        <Skeleton height="20px" />
                      </Td>
                      <Td>
                        <Skeleton height="20px" />
                      </Td>
                      <Td>
                        <Skeleton height="20px" />
                      </Td>
                      <Td>
                        <Skeleton height="20px" />
                      </Td>
                      <Td>
                        <Skeleton height="20px" />
                      </Td>
                      <Td>
                        <Skeleton height="20px" />
                      </Td>
                    </Tr>
                  ))}
                </>
              ) : (
                <>
                  {getBlockTimesData.data?.times.map(
                    (block: BlockTimesData, index: number) => (
                      <Tr key={block?.id || index} maxHeight="40px">
                        <Td>{block?.name}</Td>
                        <Td>
                          {block?.start_time !== null ? (
                            <Text>
                              {formatTimeZone(
                                new Date(block?.start_time),
                                'dd/MM/yyyy HH:mm',
                                {
                                  timeZone: decodeToken?.clinic?.timezone
                                }
                              )}
                            </Text>
                          ) : (
                            <Text>
                              {formatTimeZone(
                                new Date(
                                  0,
                                  0,
                                  0,
                                  Number(
                                    block?.recurrent_start_time?.substring(0, 2)
                                  ),
                                  Number(
                                    block?.recurrent_start_time?.substring(3, 5)
                                  )
                                ),
                                'HH:mm',
                                {
                                  timeZone: decodeToken?.clinic?.timezone
                                }
                              )}
                            </Text>
                          )}
                        </Td>
                        <Td>
                          {/* {format(
                            new Date(
                              Number(block?.finish_time?.substring(0, 4)),
                              Number(block?.finish_time?.substring(5, 7)) - 1,
                              Number(block?.finish_time?.substring(8, 10)),
                              Number(block?.finish_time?.substring(11, 13)),
                              Number(block?.finish_time?.substring(14, 16))
                            ),
                            'dd/MM/yyyy HH:mm',
                            { locale: LocaleApp }
                          )} */}
                          {block?.finish_time !== null ? (
                            <Text>
                              {formatTimeZone(
                                new Date(block?.finish_time),
                                'dd/MM/yyyy HH:mm',
                                {
                                  timeZone: decodeToken?.clinic?.timezone
                                }
                              )}
                            </Text>
                          ) : (
                            <Text>
                              {formatTimeZone(
                                new Date(
                                  0,
                                  0,
                                  0,
                                  Number(
                                    block?.recurrent_finish_time?.substring(
                                      0,
                                      2
                                    )
                                  ),
                                  Number(
                                    block?.recurrent_finish_time?.substring(
                                      3,
                                      5
                                    )
                                  )
                                ),
                                'HH:mm',
                                {
                                  timeZone: decodeToken?.clinic?.timezone
                                }
                              )}
                            </Text>
                          )}
                        </Td>
                        <Td>
                          {block?.specialists?.map((specialist) => (
                            <Tooltip
                              label={specialist?.name}
                              aria-label={specialist?.name}
                            >
                              <Box>
                                <Text>{specialist?.social_name}</Text>
                              </Box>
                            </Tooltip>
                          ))}
                        </Td>
                        <Td>
                          {block?.description !== null && (
                            <Tooltip
                              label={block?.description || ''}
                              aria-label={block?.description || ''}
                              hasArrow
                              placement="top-start"
                            >
                              <Box ml="1rem" w="24px">
                                <Text noOfLines={1}>
                                  {block?.description || ''}
                                </Text>
                              </Box>
                            </Tooltip>
                          )}
                        </Td>
                        <Td>
                          <Text>
                            {formatTimeZone(
                              new Date(block?.created_at),
                              'dd/MM/yyyy HH:mm',
                              {
                                timeZone: decodeToken?.clinic?.timezone
                              }
                            )}
                          </Text>
                        </Td>
                        <Td align="center" justifyContent="center">
                          <Flex
                            flexDirection="row"
                            align="center"
                            justify="center"
                            gap="2"
                          >
                            <Link
                              to={{
                                pathname: `/settings/block-times/${block?.id}`,
                                state: block
                              }}
                            >
                              <Tooltip
                                label={t('edit')}
                                aria-label={t('edit')}
                                hasArrow
                                placement="top-start"
                              >
                                <Box ml="1rem" w="24px">
                                  <FiEdit
                                    color="#9699B0"
                                    size={16}
                                    style={{ cursor: 'pointer' }}
                                  />
                                </Box>
                              </Tooltip>
                            </Link>
                            <Tooltip
                              label={t('exclude')}
                              aria-label={t('exclude')}
                              hasArrow
                              placement="top-start"
                              onClick={() => {
                                setIdExclude(block.id)
                                onOpen()
                              }}
                            >
                              <Box ml="1rem" w="24px">
                                <AiFillDelete
                                  color="#9699B0"
                                  size={16}
                                  style={{ cursor: 'pointer' }}
                                  onClick={() => {
                                    setIdExclude(block.id)
                                    onOpen()
                                  }}
                                />
                              </Box>
                            </Tooltip>
                          </Flex>
                        </Td>
                      </Tr>
                    )
                  )}
                </>
              )}
            </Tbody>
          </Table>
          {getBlockTimesData.data?.totalCountOfPage === 0 &&
            !getBlockTimesData.isLoading && <NotFound />}
        </Box>
        {!(
          getBlockTimesData.data?.totalCountOfPage === 0 &&
          !getBlockTimesData.isLoading
        ) && (
          <Pagination
            totalCountOfRegisters={
              getBlockTimesData.data?.totalCountOfPage || 0
            }
            currentPage={currentPage}
            onPageChange={setCurrentPage}
          />
        )}
      </LayoutList>
      <ModalConfirmExclude
        isOpen={isOpen}
        title="Confirma Exclusão:"
        onConfirmExclude={async () => {
          await handleExcludedBlockById(idExclude)
          onClose()
        }}
        onClose={onClose}
      >
        <Text>Tem certeza que quer excluir este bloqueio?</Text>
      </ModalConfirmExclude>
    </>
  )
}

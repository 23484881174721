/* eslint-disable no-debugger */
/* eslint-disable camelcase */
/* eslint-disable react-hooks/exhaustive-deps */
import {
  Avatar,
  Box,
  Button,
  Flex,
  Icon,
  SimpleGrid,
  Spinner,
  Stack,
  Tag,
  Text,
  Textarea,
  useToast
} from '@chakra-ui/react'
import { format, subDays } from 'date-fns'
import React, { FC, useEffect, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { AiFillTags } from 'react-icons/ai'
import { MdNewReleases } from 'react-icons/md'
import { useParams } from 'react-router-dom'
import { Input } from '../../components/Form/Input'
import { InputPhone2 } from '../../components/Form/InputPhone2'
import { SelectComponent } from '../../components/Form/SelectComponent'
import { SelectComponentAsync } from '../../components/Form/SelectComponentAsync'
import { TextFieldControlled } from '../../components/Form/TextFieldControlled'
import { ModalRegister } from '../../components/Modal/modalRegister'
import { CardContainer } from '../../components/molecules/CardContainer'
import { useAuth } from '../../hooks/auth'
import { Scheduling } from '../../hooks/scheduling/useScheduling'
import { useModal } from '../../hooks/useModal'
import { LayoutDefault } from '../../layouts/Default'
import { apiAuth } from '../../services/apiAuth'
import { remaskCaractersAll } from '../../utils/fns/removeCaracters'
import { mask } from '../../utils/mask'
import { useLogic } from './logic'
import { TagsData, useService } from './service'

type FormData = {
  observation: string
}

type ParamsProps = {
  id: string
}

type TagsViewData = (TagsData | undefined)[] | undefined

type ContactsData = {
  contact: string
  ddi_country: string
  id: string
  is_main: boolean
  type_of_contact: string
}

interface TagsModal {
  value: string
  label: string
}

type TagsModalData = Array<TagsModal> | []

interface Indication {
  id: number
  service: {
    id: number
    name: string
    observation: string
    is_active: boolean
    price: string
    days_to_come_back: number
    days_to_maturity: number
    protocol: string
    service_sessions: Array<{
      id: number
      duration: number
      time_to_be_there: number
      session_number: number
      service_id: number
    }>
    service_costs: unknown
    service_documents: unknown
    tag_service: {
      id: number
      name: string
      is_active: boolean
      observation: string
    }
  }
  service_name: string
  observation: string
  status: number
  indication_date: string | null
  specialist_name: string
  medical_record_finish_date: string
  updated_at: string
  updated_by_name: string | null
}

type Indications = Array<{
  label: string
  value: number
  idService: number
  nameService: string
}>

interface Sections {
  id: number
  session_number: number
  status: number
  description: string | null
  attendance_id: number
  service_id: number
  service_name: string
  total_session: number
}

interface SectionsSelect {
  value: number
  label: string
  serviceId: number
  serviceName: string
}

export const SchedulingUpdate: FC = () => {
  // hooks
  const { permissionFunction } = useAuth()
  const { LocaleApp } = useModal()
  const isMaster = permissionFunction('SCHEDULE_CORRECTION', 'EDIT')
  const toast = useToast()
  const { id } = useParams<ParamsProps>()
  const [state, setState] = useState<Scheduling | null>(null)
  const services = useService()
  const logic = useLogic()
  // new Date default schedule
  const exactDay = new Date().getDate()
  const exactMonth = new Date().getMonth()
  const exactYear = new Date().getFullYear()
  const exactHours = new Date().getHours()
  const exactMinutes = new Date().getMinutes()

  const exactDate = new Date()
  const minDate = subDays(exactDate, 0)

  const [t] = useTranslation('pageScheduling')
  const { register, handleSubmit, formState } = useForm<FormData>({})
  // modal state
  const [isOpen, setIsOpen] = useState(false)
  const [name, setName] = useState('')
  const [numbers, setNumbers] = useState<ContactsData[]>([
    {
      contact: '',
      ddi_country: '+55',
      id: '',
      is_main: true,
      type_of_contact: 'telefone'
    }
  ])
  const [patientsTags, setPatientsTags] = useState<TagsModalData>([])
  const [observation, setObservation] = useState('')
  // tags for view
  const [tagsView, setTagsView] = useState<TagsViewData>()
  // loading tags view
  const [isLoadingTagsView, setIsLoadingTagsView] = useState(false)
  const [isBackLoading, setIsBackLoading] = useState(true)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [observationScheduling, setObservationScheduling] = useState('')
  // indications state
  const [indicationsOptions, setIndicationsOptions] = useState<Indications>([])
  const [indicationsSelected, setIndicationsSelected] = useState<Indications>(
    []
  )
  const [sectionsOptions, setSectionsOptions] = useState<Sections[]>([])
  const [sectionsSelected, setSelectionsSelect] = useState<SectionsSelect[]>([])

  // getAttendanceData
  useEffect(() => {
    apiAuth.get<Scheduling>(`clinics/attendances/${id}/`).then((response) => {
      setState(response.data)
    })
  }, [])

  // getValues Update
  useEffect(() => {
    // container 1
    if (state?.professional_id) {
      logic.setSpecialistSelect({
        value: state.professional_id,
        label: state.specialist_name || ''
      })
    }

    if (state?.attendance_spot) {
      logic.setSpotSelect({
        value: state.attendance_spot.id,
        label: state.attendance_spot.name
      })
    }

    if (state?.observation) {
      setObservationScheduling(state.observation)
    }

    // container 2
    if (state?.patient_id) {
      logic.setClientSelect({
        value: state?.patient_id,
        label: state?.patient_name || ''
      })
    }
    // container 4
    if (state?.scheduled_arrival_time) {
      const date = state.scheduled_arrival_time.substring(0, 10)
      logic.setAttendanceDate(date)
    }

    if (state?.scheduled_arrival_time) {
      const arrived = state.scheduled_arrival_time.substring(11, 16)
      logic.setTimeToBeThere(arrived)
    }

    if (state?.scheduled_initial_time) {
      const init = state.scheduled_initial_time.substring(11, 16)
      logic.setStartHours(init)
    }

    if (state?.scheduled_final_time) {
      const final = state.scheduled_final_time.substring(11, 16)
      logic.setEndHours(final)
    }

    if (state?.indications && state.indications?.length > 0) {
      const oldIndications = state?.indications?.map((ind: any) => {
        return {
          label: `${format(
            new Date(
              Number(
                ind?.indication_date?.substring(0, 4) ||
                  ind?.medical_record_finish_date?.substring(0, 4)
              ),
              Number(
                ind?.indication_date?.substring(5, 7) ||
                  ind?.medical_record_finish_date?.substring(5, 7)
              ) - 1,
              Number(
                ind?.indication_date?.substring(8, 10) ||
                  ind?.medical_record_finish_date?.substring(8, 10)
              )
            ),
            'dd/MM/yyyy',
            {
              locale: LocaleApp
            }
          )} - ${ind?.specialist_name} - ${ind?.service_name}`,
          value: ind?.id,
          idService: ind?.service?.id,
          nameService: ind?.service_name
        }
      })

      setIndicationsSelected(oldIndications || [])
    }

    if (state?.sessions && state?.sessions?.length > 0) {
      const oldSessions = state?.sessions?.map((sec: any) => {
        return {
          value: sec?.id || sec?.attendance_id,
          label: `${sec?.service_name} - ${sec?.session_number}/${sec?.total_session}`,
          serviceId: sec?.service_id,
          serviceName: sec?.service_name
        }
      })

      setSelectionsSelect(oldSessions)
    }
  }, [id, state])

  // getValues Update sync
  useEffect(() => {
    // container 3
    if (state?.attendance_items) {
      if (state.attendance_items.length > 0) {
        if (state?.ticket_status_payment) {
          const servicesAttendance = state.attendance_items.map(
            (attServe: any) => {
              return {
                value: attServe.service_id,
                label: attServe.service_name,
                isFixed: true
              }
            }
          )

          logic.setServicesSelects(servicesAttendance)
          setIsBackLoading(true)
        } else {
          const servicesAttendance = state.attendance_items.map(
            (attServe: any) => {
              return {
                value: attServe.service_id,
                label: attServe.service_name
              }
            }
          )

          logic.setServicesSelects(servicesAttendance)
          setIsBackLoading(true)
        }
      }
    }
  }, [id, state])

  // format services by id array for body
  function formatServices() {
    if (logic.servicesSelects === null) {
      return []
    }

    // array select
    const arrayId = logic.servicesSelects.map((service) => {
      return {
        service_id: service.value,
        service_name: service.label
      }
    })

    const allArrayReturn = arrayId.map((item) =>
      state?.attendance_items?.find((r) => item.service_id === r.service_id)
    )

    const notArrayReturn = state?.attendance_items?.map((item) =>
      arrayId.find((r) => item.service_id === r.service_id)
    )

    const createArray = arrayId.filter(
      (newItem) => !notArrayReturn?.includes(newItem)
    )

    const arrayFilterReturn = allArrayReturn?.filter(Boolean)

    if (arrayFilterReturn !== undefined) {
      if (arrayFilterReturn?.length > 0) {
        return [...arrayFilterReturn, ...createArray]
      }
    }

    return [...arrayId]
  }

  // format sections,
  function formatSections() {
    const arraySections = sectionsSelected?.map((section) => {
      return {
        id: section?.value
      }
    })

    return arraySections
  }

  // return array indications

  function formatIndications() {
    const ArrayInd = indicationsSelected?.map((ind) => {
      return {
        id: ind?.value
      }
    })

    return ArrayInd
  }

  // format services excludes
  function verifyServicesExcludeInSelect() {
    const oldServices = state?.attendance_items.map((item) => {
      return item.service_id
    })
    const servicesSelects = logic.servicesSelects?.map((service) => {
      return service.value
    })
    const servicesExcludes = oldServices?.filter(
      (exclude) => !servicesSelects?.includes(exclude)
    )

    return servicesExcludes || []
  }

  // validation hours for body
  function validationHours() {
    let validationReturn = false
    const { timeToBeThere, startHours, endHours } = logic

    const hoursValidate = [timeToBeThere, startHours, endHours]
    hoursValidate.forEach((hour) => {
      if (hour.length !== 5) {
        validationReturn = true
      }

      if (hour.length === 5) {
        const hoursRemask = remaskCaractersAll(hour)
        const minutes = hoursRemask.substring(2, 4)

        if (Number(hoursRemask) > 2359) {
          validationReturn = true
        }

        if (Number(minutes) > 59) {
          validationReturn = true
        }
      }
    })

    return validationReturn
  }

  // validation date and hours pass
  function validationDateAndHoursPass() {
    let validationReturn = false
    const day = Number(logic.attendanceDate.substring(8, 10))
    const month = Number(logic.attendanceDate.substring(5, 7)) - 1
    const year = Number(logic.attendanceDate.substring(0, 4))
    const hours = Number(
      remaskCaractersAll(logic.timeToBeThere).substring(0, 2)
    )
    const minutes = Number(
      remaskCaractersAll(logic.timeToBeThere).substring(2, 4)
    )

    if (year < exactYear) {
      validationReturn = true
    }

    if (year === exactYear) {
      if (month < exactMonth) {
        validationReturn = true
      } else if (month === exactMonth) {
        if (day < exactDay) {
          validationReturn = true
        } else if (day === exactDay) {
          if (hours < exactHours) {
            validationReturn = true
          } else if (hours === exactHours) {
            // minutes default tolerance 10 minutes
            if (minutes < exactMinutes - 10) {
              validationReturn = true
            }
          }
        }
      }
    }

    return validationReturn
  }

  // validation hours start specialist EndAttendance
  function validationHoursArrivedTimeSpecialistEndAttendance() {
    let isError = false

    const start = Number(remaskCaractersAll(logic.timeToBeThere))
    const attendance = Number(remaskCaractersAll(logic.startHours))
    const end = Number(remaskCaractersAll(logic.endHours))

    if (start > attendance) {
      isError = true
      return isError
    }

    if (attendance > end) {
      isError = true
      return isError
    }

    return isError
  }

  function validateTicketRemoveService() {
    let validationReturn = false

    if (state?.ticket_status_payment !== undefined) {
      if (state?.ticket_status_payment !== null) {
        if (logic?.servicesSelects !== null) {
          if (
            state?.attendance_items?.length > logic?.servicesSelects?.length
          ) {
            validationReturn = true
            return validationReturn
          }
        }
      }
    }

    return validationReturn
  }

  // validation exist item for scheduling
  function validationExistItemScheduling() {
    let isNotValid = false

    if (
      logic.servicesSelects?.length === 0 &&
      sectionsSelected?.length === 0 &&
      indicationsSelected?.length === 0
    ) {
      isNotValid = true
    }

    return isNotValid
  }

  const handleUpdateScheduling: SubmitHandler<FormData> = async (
    values,
    event
  ) => {
    event?.preventDefault()

    // validates
    if (state?.block_guide) {
      toast({
        title: 'Atenção!',
        description: `Não é possível fazer alterações em guias bloqueadas!`,
        status: 'info',
        isClosable: true,
        duration: 2000,
        position: 'top'
      })

      setIsSubmitting(false)
      return
    }

    const validationGuideAndTicket = validateTicketRemoveService()

    if (validationGuideAndTicket) {
      toast({
        title: 'Atenção!',
        description: `Não é possível remover serviços de guias que estão associadas a um ticket!`,
        status: 'warning',
        isClosable: true,
        duration: 2000,
        position: 'top'
      })

      setIsSubmitting(false)
      return
    }

    if (logic.specialistSelect === null) {
      toast({
        title: 'Aconteceu um error!',
        description: `O especialista é obrigatório para agendar.`,
        status: 'error',
        isClosable: true,
        duration: 2000,
        position: 'top'
      })

      setIsSubmitting(false)
      return
    }

    if (logic.spotSelect === null) {
      toast({
        title: 'Aconteceu um error!',
        description: `O Local de atendimento é obrigatório para agendar.`,
        status: 'error',
        isClosable: true,
        duration: 2000,
        position: 'top'
      })

      setIsSubmitting(false)
      return
    }

    if (logic.clientSelect === null) {
      toast({
        title: 'Aconteceu um error!',
        description: `O Cliente é obrigatório para agendar.`,
        status: 'error',
        isClosable: true,
        duration: 2000,
        position: 'top'
      })

      setIsSubmitting(false)
      return
    }

    const returnExistScheduling = validationExistItemScheduling()

    if (returnExistScheduling === true) {
      toast({
        title: 'Aconteceu um error!',
        description: `Selecione ao menos um item para agendamento`,
        status: 'error',
        isClosable: true,
        duration: 2000,
        position: 'top'
      })

      setIsSubmitting(false)
      return
    }

    const returnValidationHours = validationHours()

    if (returnValidationHours === true) {
      toast({
        title: 'Aconteceu um error!',
        description: `Revise os horários, para o agendamento!`,
        status: 'error',
        isClosable: true,
        duration: 2000,
        position: 'top'
      })

      setIsSubmitting(false)
      return
    }

    const returnValidation = validationHoursArrivedTimeSpecialistEndAttendance()

    if (returnValidation === true) {
      toast({
        title: 'Revise seus horários',
        description: `O horário de agenda,
        não pode ser maior que o de atendimento
        e previsão de finalização não pode
        ser menor que a previsão de atendimento`,
        status: 'error',
        isClosable: true,
        duration: 2000,
        position: 'top'
      })

      setIsSubmitting(false)
      return
    }
    // end validations

    const attendance_items = formatServices()
    const sessions = formatSections()
    const indications = formatIndications()

    const payload = {
      guide: state?.guide,
      observation: observationScheduling === '' ? null : observationScheduling,
      scheduled_arrival_time: `${logic.attendanceDate}T${logic.timeToBeThere}`,
      scheduled_initial_time: `${logic.attendanceDate}T${logic.startHours}`,
      scheduled_final_time: `${logic.attendanceDate}T${logic.endHours}`,
      patient_id: logic.clientSelect?.value,
      attendance_items,
      sessions,
      indications,
      attendance_items_exclude: verifyServicesExcludeInSelect(),
      professional_id: logic.specialistSelect?.value,
      attendance_spot: { id: logic.spotSelect?.value },
      status: state?.status,
      block_guide: state?.block_guide,
      invoice_number: state?.invoice_number,
      is_active: state?.is_active,
      observation_payment: state?.observation_payment,
      real_arrival_time: state?.real_arrival_time,
      real_final_time: state?.real_final_time,
      real_initial_time: state?.real_initial_time,
      value_to_income: state?.value_to_income,
      sessions_not_performed: state?.sessions_not_performed || []
    }

    await services.handlerUpdateAttendanceRedirect(payload, id)
  }

  // modal functions
  const onClose = () => {
    setIsOpen(false)
  }

  const handleSelectedTags = (event: any) => {
    setPatientsTags(event)
  }

  function handleChangeInputNumbers(
    index: number,
    e: React.ChangeEvent<HTMLInputElement>
  ) {
    const values: any = [...numbers]

    if (values[index].ddi_country === '+55') {
      values[index].contact = mask(e.target.value, '(99) 99999-9999')
    } else {
      values[index].contact = e.target.value
    }

    setNumbers(values)
  }

  // format tags
  function formatTags() {
    if (patientsTags.length > 0) {
      const serviceTags = patientsTags.map((tag) => {
        return Number(tag.value)
      })

      return serviceTags
    }

    return patientsTags
  }

  // create new client modal
  const handlerCreateNewClient = async () => {
    if (name === '') {
      toast({
        title: 'Aconteceu um error!',
        description: `O nome do cliente é obrigatório`,
        status: 'error',
        isClosable: true,
        duration: 2000,
        position: 'top'
      })

      return
    }

    const contacts_patient = numbers.map((item: any) => {
      if (item.contact !== '') {
        return {
          ddi_country: item.ddi_country,
          contact: item.contact,
          is_main: item.is_main,
          type_of_contact: item.type_of_contact
        }
      }

      return {
        invalid: true
      }
    })
    const patient_tags = formatTags()

    const payload = {
      name,
      observation,
      patient_tags,
      contacts_patient:
        contacts_patient[0].invalid === true ? [] : contacts_patient || []
    }

    try {
      const { data } = await apiAuth.post(`clinics/patients/`, payload)

      const client = {
        value: Number(data.id),
        label: String(data.name)
      }

      if (data.id !== undefined) {
        toast({
          title: 'Atualização realizado com sucesso!',
          description: 'vamos atualizar a lista para você.',
          status: 'success',
          isClosable: true,
          duration: 3000,
          position: 'top'
        })

        services.UpdateClients()

        setTimeout(() => {
          onClose()
          setNumbers([
            {
              contact: '',
              ddi_country: '+55',
              id: '',
              is_main: true,
              type_of_contact: 'telefone'
            }
          ])
          setName('')
          setObservation('')
          setPatientsTags([])
          logic.setClientSelect(client)
        }, 2000)
      }
    } catch (error: any) {
      toast({
        title: 'Aconteceu um error!',
        description: `${error.message}`,
        status: 'error',
        isClosable: true,
        duration: 3000,
        position: 'top'
      })
    }
  }

  // view Tags Values
  const UpdateTagsHandleChangeClient = () => {
    setIsLoadingTagsView(true)

    const { infoClient } = logic
    const tagsClient = infoClient?.patient_tags
    const tagsPatient = tagsClient?.map((tag) =>
      services?.tags?.find((tagItem) => tagItem.id === tag)
    )

    setTagsView(tagsPatient)
    setIsLoadingTagsView(false)
  }

  // calculate new hours prevision Start and End
  const handleActionButtonCalculateHours = async () => {
    const validationSelectItem = validationExistItemScheduling()

    // services null
    if (validationSelectItem === true) {
      toast({
        status: 'warning',
        position: 'top',
        description:
          'Para calcular, selecione pelo menos 1 item para agendamento.',
        duration: 3000,
        title: 'Atenção!',
        isClosable: true
      })

      return
    }

    // invalid length hours
    if (logic.timeToBeThere.length !== 5) {
      toast({
        status: 'warning',
        position: 'top',
        description: 'A Hora de chegada não foi informada corretamente!',
        duration: 3000,
        title: 'Atenção!',
        isClosable: true
      })

      return
    }

    const arrival_time = `${logic?.attendanceDate}T${logic?.timeToBeThere}`

    const services_ids =
      logic?.servicesSelects?.map((ser) => {
        return ser?.value
      }) || []

    const sessions_ids = sectionsSelected?.map((s) => {
      return s?.value
    })

    const indications_ids = indicationsSelected?.map((i) => {
      return i?.value
    })

    try {
      const { data } = await apiAuth.post<{
        specialist_time: string
        prevision_finish: string
      }>(`clinics/attendances/calculate-times/`, {
        arrival_time,
        services_ids,
        sessions_ids,
        indications_ids
      })

      if (data?.specialist_time) {
        logic.setStartHours(data.specialist_time?.substring(11, 16))
      }

      if (data?.prevision_finish) {
        logic.setEndHours(data.prevision_finish?.substring(11, 16))
      }
    } catch (error: any) {
      toast({
        status: 'warning',
        position: 'top',
        description: 'Não foi possível calcular horários',
        duration: 3000,
        title: 'Atenção!',
        isClosable: true
      })
    }
  }

  // view Tags Values Effect
  useEffect(() => {
    UpdateTagsHandleChangeClient()
  }, [logic.infoClient])

  // clear Hours case change Services
  useEffect(() => {
    if (logic.serInfo !== undefined) {
      logic.serInfo?.forEach((ser) => {
        ser?.service_sessions !== undefined

        if (!isBackLoading) {
          logic.setStartHours('')
          logic.setEndHours('')
        }

        setIsBackLoading(false)
      })
    }
  }, [logic.serInfo])

  // get values services for calculate hours for input
  useEffect(() => {
    if (logic.servicesSelects !== null) {
      if (logic.servicesSelects?.length > 0) {
        const { servicesSelects } = logic
        const defaultServices = services.services
        const valuesServices = servicesSelects.map((service) =>
          defaultServices.find((ser) => service.value === ser.id)
        )

        logic.setSerInfo(valuesServices)
      } else {
        logic.setSerInfo(null)
      }
    }
  }, [logic.servicesSelects])

  useEffect(() => {
    if (logic.clientSelect !== null) {
      apiAuth
        .get(`clinics/patients/${logic?.clientSelect?.value}/indications/`, {
          params: {
            status: 1
          }
        })
        .then((response) => {
          if (response?.data?.count > 0) {
            const arrayOptionsFormatted: Indications =
              response?.data?.results?.map((ind: Indication) => {
                return {
                  label: `${format(
                    new Date(
                      Number(
                        ind?.indication_date?.substring(0, 4) ||
                          ind?.medical_record_finish_date?.substring(0, 4)
                      ),
                      Number(
                        ind?.indication_date?.substring(5, 7) ||
                          ind?.medical_record_finish_date?.substring(5, 7)
                      ) - 1,
                      Number(
                        ind?.indication_date?.substring(8, 10) ||
                          ind?.medical_record_finish_date?.substring(8, 10)
                      )
                    ),
                    'dd/MM/yyyy',
                    {
                      locale: LocaleApp
                    }
                  )} - ${ind?.specialist_name} - ${ind?.service_name}`,
                  value: ind?.id,
                  idService: ind?.service?.id,
                  nameService: ind?.service_name
                }
              })

            setIndicationsOptions(arrayOptionsFormatted)
          } else {
            setIndicationsOptions([])
          }
        })

      apiAuth
        .get<Sections[]>(
          `clinics/patients/${logic.clientSelect?.value}/service-sessions/`,
          {
            params: {
              status: '1',
              min_session: '1'
            }
          }
        )
        .then(({ data }) => {
          setSectionsOptions(data)
        })
    }

    return () => {}
  }, [logic.clientSelect])

  return (
    <>
      <LayoutDefault
        urlBack="/scheduling"
        title={t('update.title')}
        titleBack="Agenda"
        onSubmit={handleSubmit(handleUpdateScheduling)}
      >
        <Stack justify="space-evenly" spacing="4" w="100%">
          <CardContainer title={t('create.container1.title')}>
            <Stack w="100%" direction="row" my="2" spacing="6">
              {services.isLoadingSpecialists ? (
                <Flex w="60%" align="center" justify="center">
                  <Spinner color="blue" m="2" />
                </Flex>
              ) : (
                <Box w="60%">
                  <SelectComponent
                    title={t('create.container1.i1')}
                    isRequired
                    isDisabled={state?.block_guide}
                    placeholder={t('create.container1.placeholder.specialist')}
                    options={services.specialistsEdit.map((specialist) => {
                      return {
                        value: specialist.user.id,
                        label: specialist.social_name
                      }
                    })}
                    value={logic.specialistSelect}
                    onChange={(event: any) =>
                      logic.handleSelectSpecialist(event)
                    }
                    name="specialist"
                  />
                </Box>
              )}
              {services.isLoadingSpots ? (
                <Flex w="40%" align="center" justify="center">
                  <Spinner color="blue" m="2" />
                </Flex>
              ) : (
                <Box w="40%">
                  <SelectComponent
                    title={t('create.container1.i2')}
                    isDisabled={state?.block_guide}
                    isRequired
                    placeholder={t('create.container1.placeholder.spot')}
                    options={services.spots.map((spot) => {
                      return {
                        value: spot.id,
                        label: spot.name
                      }
                    })}
                    value={logic.spotSelect}
                    onChange={(event: any) => logic.handleSelectSpot(event)}
                    name="spot"
                  />
                </Box>
              )}
            </Stack>
            <Box w="100%">
              <Text my="2" fontSize="sm">
                {t('create.container1.i3')}
              </Text>
              <Textarea
                value={observationScheduling}
                disabled={state?.block_guide}
                onChange={(event) =>
                  setObservationScheduling(event.target.value)
                }
                placeholder={t('create.container1.placeholder.observation')}
              />
            </Box>
          </CardContainer>

          <CardContainer title={t('create.container2.title')}>
            {services.isLoadingClients ? (
              <>
                <Flex w="100%" align="center" justify="center">
                  <Spinner color="blue" m="2" />
                </Flex>
              </>
            ) : (
              <>
                <Stack w="100%" direction="row" my="2" spacing="6">
                  <Box>
                    <Avatar size="lg" src={logic.clientAvatar || ''} />
                  </Box>
                  <Box w="50%">
                    <SelectComponentAsync
                      title={t('create.container2.i1')}
                      isRequired
                      isDisabled={state?.block_guide}
                      placeholder={t('create.container2.placeholder.client')}
                      loadOptions={(values: string) =>
                        services.getClients(values)
                      }
                      value={logic.clientSelect}
                      onChange={(e: any) => logic.handleSelectClient(e)}
                      name="client"
                    />
                  </Box>
                  <Box w="40%">
                    <Input
                      label={t('create.container2.i2')}
                      isDisabled
                      value={logic.clientPhone}
                      placeholder={t('create.container2.placeholder.phone')}
                      name="phone"
                    />
                  </Box>
                </Stack>
                <Flex my="2" w="100%">
                  <Box w="50%">
                    <Text fontSize="sm">
                      {t('create.container2.i3')}
                      <Icon mx="2" size={22} as={AiFillTags} />
                    </Text>
                    <SimpleGrid spacing="6px">
                      {logic.clientSelect === null ? (
                        <>
                          <Box p="2">
                            <Text fontSize="sm">
                              {t('create.container2.clientNull')}
                            </Text>
                          </Box>
                        </>
                      ) : (
                        <>
                          {isLoadingTagsView ? (
                            <Flex w="100%" align="center" justify="center">
                              <Spinner color="blue" m="2" />
                            </Flex>
                          ) : (
                            <>
                              <Box p="2">
                                {/* map Array tags */}
                                {tagsView !== undefined &&
                                  tagsView?.map((tagCard) => {
                                    return (
                                      <Tag
                                        key={tagCard?.id}
                                        variant="solid"
                                        mx="6px"
                                        colorScheme="blue"
                                      >
                                        {tagCard?.name}
                                      </Tag>
                                    )
                                  })}
                                {tagsView !== undefined &&
                                  tagsView.length === 0 && (
                                    <Text fontSize="sm" fontWeight="bold">
                                      {t('create.container2.clientTagsNull')}
                                    </Text>
                                  )}
                              </Box>
                            </>
                          )}
                        </>
                      )}
                    </SimpleGrid>
                  </Box>
                  <Box w="50%">
                    <Text my="2" fontSize="sm">
                      {t('create.container2.i4')}
                      <Icon mx="2" size={22} as={MdNewReleases} />
                    </Text>
                    <Textarea
                      value={logic.clientObservation}
                      name="observation"
                      isDisabled
                    />
                  </Box>
                </Flex>
                {/* <Box mt="5">
                  <Button
                    size="sm"
                    fontSize="sm"
                    bg="blue.300"
                    disabled
                    color="#fff"
                    isLoading={formState.isSubmitting}
                    onClick={() => setIsOpen(true)}
                  >
                    {t('create.container2.btnLabel')}
                  </Button>
                </Box> */}
              </>
            )}
          </CardContainer>

          <CardContainer title={t('create.container5.title')}>
            <Stack w="100%" direction="row" my="2" spacing="6">
              <>
                <SelectComponent
                  title={t('create.container5.i1')}
                  placeholder={t('create.container5.placeholder.indications')}
                  options={indicationsOptions}
                  value={indicationsSelected}
                  onChange={(event: Indications) => {
                    setIndicationsSelected(event)
                  }}
                  isMulti
                  name="indications"
                />
              </>
            </Stack>
          </CardContainer>

          <CardContainer
            title={t('create.container3.title')}
            isLoading={services.isLoadingService}
          >
            <Stack w="100%" direction="row" my="2" spacing="6">
              {services.isLoadingService ? (
                <>
                  <Flex w="100%" align="center" justify="center">
                    <Spinner color="blue" m="2" />
                  </Flex>
                </>
              ) : (
                <>
                  <SelectComponent
                    title={t('create.container3.i1')}
                    isDisabled={state?.block_guide}
                    placeholder={t('create.container3.placeholder.service')}
                    isClearable={logic?.servicesSelects?.some(
                      (v) => !v.isFixed
                    )}
                    options={services.services.map((service) => {
                      return {
                        value: service.id,
                        label: service.name
                      }
                    })}
                    value={logic.servicesSelects}
                    onChange={(event: any) => logic.handleSelectServices(event)}
                    isMulti
                    isLoading={services.isLoadingService}
                    name="services"
                  />
                </>
              )}
            </Stack>
          </CardContainer>

          <CardContainer title={t('create.container4.title')}>
            <Stack w="100%" direction="row" my="2" spacing="6">
              <>
                <SelectComponent
                  title={t('create.container4.i1')}
                  placeholder={t('create.container4.placeholder.sections')}
                  options={sectionsOptions?.map((sec) => {
                    return {
                      value: sec?.id || sec?.attendance_id,
                      label: `${sec?.service_name} - ${sec?.session_number}/${sec?.total_session}`,
                      serviceId: sec?.service_id,
                      serviceName: sec?.service_name
                    }
                  })}
                  isMulti
                  value={sectionsSelected}
                  onChange={(event: SectionsSelect[]) => {
                    setSelectionsSelect(event)
                  }}
                  name="sections"
                />
              </>
            </Stack>
          </CardContainer>

          <CardContainer title={t('create.container6.title')}>
            <Stack w="100%" direction="row" my="2" spacing="6">
              <Box w="25%">
                <Input
                  label={t('create.container6.i1')}
                  isRequired
                  isDisabled={state?.block_guide}
                  type="date"
                  min={
                    !isMaster
                      ? `${format(minDate, 'yyyy-MM-dd', {
                          locale: LocaleApp
                        })}`
                      : undefined
                  }
                  value={logic.attendanceDate}
                  onChange={(event) =>
                    logic.setAttendanceDate(event.target.value)
                  }
                  name="date"
                />
              </Box>
              <Box w="25%">
                <TextFieldControlled
                  label={t('create.container6.i2')}
                  name="timeToBeThere"
                  isRequired
                  isDisabled={state?.block_guide}
                  mask="time"
                  initialValue={logic.timeToBeThere}
                  onKeyUp={(event) => {
                    logic.handleInputHoursArrivedTime(event)
                  }}
                  placeholder="00:00"
                  textAlign="left"
                />
              </Box>
              <Box w="25%">
                <TextFieldControlled
                  label={t('create.container6.i3')}
                  name="serviceTime"
                  isDisabled={state?.block_guide}
                  mask="time"
                  initialValue={logic.startHours}
                  onKeyUp={(event) => {
                    logic.handleInputHoursStartAttendanceTime(event)
                  }}
                  placeholder="00:00"
                  textAlign="left"
                />
              </Box>
              <Box w="25%">
                <TextFieldControlled
                  label={t('create.container6.i4')}
                  name="endHours"
                  isDisabled={state?.block_guide}
                  mask="time"
                  initialValue={logic.endHours}
                  onKeyUp={(event) => {
                    logic.handleInputHoursEndAttendanceTime(event)
                  }}
                  placeholder="00:00"
                  textAlign="left"
                />
              </Box>
            </Stack>

            <Box mt="5">
              {/* hours default */}
              <Button
                size="sm"
                fontSize="sm"
                bg="blue.300"
                color="#fff"
                onClick={() => handleActionButtonCalculateHours()}
              >
                {t('create.container6.btnLabel')}
              </Button>
            </Box>
          </CardContainer>

          <Flex mt="4" w="100%" align="flex-end" justifyContent="flex-end">
            <Button
              type="submit"
              size="md"
              fontSize="md"
              bg="blue.300"
              color="#fff"
              isLoading={formState.isSubmitting || isSubmitting}
            >
              {t('create.button')}
            </Button>
          </Flex>

          <Stack w="100%" my="2" h="300px" />
        </Stack>
      </LayoutDefault>
      <ModalRegister
        title={t('modal.title')}
        isOpen={isOpen}
        onClose={onClose}
        handlerAction={handlerCreateNewClient}
      >
        <Stack w="100%" direction="row" p="2" spacing="6">
          <Box w="60%">
            <TextFieldControlled
              name="name"
              isRequired
              isDisabled={state?.block_guide}
              label={t('modal.name')}
              value={name}
              textAlign="left"
              onChange={(event) =>
                setName(event.currentTarget.value.toUpperCase())
              }
              placeholder={t('modal.name')}
            />
          </Box>
          <Box w="40%">
            <InputPhone2
              label={`${t('modal.phone')}`}
              type="text"
              size="md"
              name="number"
              getValues={(value) => {
                const values: any = [...numbers]
                values[0].ddi_country = value.ddi
                setNumbers(values)
              }}
              value={numbers[0].contact}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleChangeInputNumbers(0, e)
              }
            />
          </Box>
        </Stack>
        <Stack w="100%" direction="row" p="2" spacing="6">
          <Box w="100%">
            <SelectComponent
              title={t('modal.tags')}
              isDisabled={state?.block_guide}
              options={services.tags.map((tag) => {
                return {
                  value: tag.id,
                  label: tag.name
                }
              })}
              isMulti
              placeholder={t('modal.placeholder.tags')}
              values={patientsTags}
              onChange={(event: any) => handleSelectedTags(event)}
              name="tags"
              noOptionsText="Nenhuma tag encontrada"
            />
          </Box>
        </Stack>
        <Stack w="100%" direction="row" p="2" spacing="6">
          <Box w="100%">
            <Text my="2" fontSize="sm">
              {t('modal.observation')}
            </Text>
            <Textarea
              name="observation"
              value={observation}
              onChange={(event) => setObservation(event.currentTarget.value)}
              placeholder={t('modal.placeholder.observation')}
            />
          </Box>
        </Stack>
      </ModalRegister>
    </>
  )
}

/* eslint-disable camelcase */
import { useQuery } from 'react-query'
import { apiAuth } from '../../services/apiAuth'

export type Scheduling = {
  id: number
  guide: number
  patient_id: number
  edit_status: 1 | 2 | 3
  hash: string
  allowed_start_attendance: boolean
  block_checkout: boolean
  status_by_system: boolean
  scheduled_arrival_time: string
  scheduled_initial_time: string
  scheduled_final_time: string
  real_arrival_time: string
  real_initial_time: string
  real_final_time: string
  status: number
  professional_id: number
  attendance_spot_id: number
  observation: string
  observation_payment: string
  value_to_income: number
  patient_number?: string
  invoice_number: number | null
  invoice_date: string | null
  block_guide: boolean
  is_active: boolean
  specialist_name: string | null
  patient_name: string | null
  sessions: Array<{
    id: number
    session_number: number
    status: number
    description: string | null
    attendance_id: number
    service_id: number
    service_name: string
    total_session: number
  }>
  sessions_not_performed: Array<{
    id: number
    session_number: number
    status: number
    description: string | null
    attendance_id: number
    service_id: number
    service_name: string
    total_session: number
  }>
  indications: Array<{
    id: number
  }>
  patient_social_name: string | null
  attendance_items_exclude?: Array<number>
  specialist_color: null | string
  ticket_status_payment?: number | null
  attendance_items: Array<{
    id: number
    service_name: string
    service_id: number
    total_session: number
  }>
  attendance_spot: {
    id: number
    index: number
    name: string
    is_active: boolean
  }
}

export type BlocksData = {
  id: number
  name: string
  description: string
  start_time: string | null
  finish_time: string | null
  is_recurrent: boolean | null
  week_day: string | null
  specialists: [
    {
      id: number
      name: string
      social_name: string
    }
  ]
  created_at: string
  expand_recurrent_days: Array<string> | null
  recurrent_start_time: string | null
  recurrent_finish_time: string | null
}

export type ResponseGetScheduling = {
  scheduling: Scheduling[]
  totalCountOfPage: number
  blocks?: BlocksData[]
}

export const getScheduling = async (
  page: number,
  search: string = '',
  startAt: string = '',
  endAt: string = '',
  specialistId: String[] | any = [],
  status: String[] | any = [],
  isActive: boolean = true,
  noPaginate: number = 0
): Promise<ResponseGetScheduling> => {
  try {
    const { data } = await apiAuth.get(`clinics/attendances/`, {
      params: {
        page,
        keyword: search,
        is_active: isActive,
        startDate: startAt,
        finishDate: endAt,
        specialistId: specialistId.join(','),
        status: status.join(','),
        noPaginate,
        onlyFields:
          noPaginate === 1
            ? 'guide,id,patient_name,patient_social_name,specialist_color,scheduled_arrival_time,scheduled_initial_time,scheduled_final_time,sessions'
            : undefined,
        removeFields:
          noPaginate === 1
            ? undefined
            : 'services_not_performed,patient_birth_date,patient_alerts,sessions_not_performed,indications'
      }
    })

    if (noPaginate === 0) {
      const totalCountOfPage = data?.count
      const scheduling: Scheduling[] = data?.results

      return {
        scheduling,
        totalCountOfPage
      }
    }

    const { data: blocksData } = await apiAuth.get(`clinics/block-times/`, {
      params: {
        expandRecurrentDays: '1',
        expandStartTime: startAt,
        expandFinishTime: endAt,
        noPaginate: '1',
        startTime: startAt,
        finishTime: endAt
      }
    })

    const totalCountOfPage = data?.length
    const scheduling: Scheduling[] = data
    const blocks: BlocksData[] = blocksData

    return {
      scheduling,
      totalCountOfPage,
      blocks
    }
  } catch (error: any) {
    const scheduling: Scheduling[] = []
    const blocks: BlocksData[] = []
    const totalCountOfPage = 0
    return {
      scheduling,
      totalCountOfPage,
      blocks
    }
  }
}

export function useScheduling(
  page: number,
  functionLoading: Promise<ResponseGetScheduling>,
  search: string,
  startAt: string = '',
  endAt: string = '',
  specialistId: String[] | String = [],
  status: String[] | String = [],
  isActive: boolean,
  options?: {}
) {
  return useQuery(
    [
      'scheduling',
      page,
      search,
      isActive,
      startAt,
      endAt,
      specialistId,
      status
    ],
    () => functionLoading,
    {
      cacheTime: 1000 * 60 * 15, // 15 minutes
      staleTime: 1000, // 15 minutes
      ...options
    }
  )
}

/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Skeleton,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
  useBreakpointValue
} from '@chakra-ui/react'
import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FiEdit } from 'react-icons/fi'
import { Link } from 'react-router-dom'
import format from 'date-fns/format'
import { Pagination } from '../../components/PaginationNew'
import { useAuth } from '../../hooks/auth'
import {
  ClientsData,
  getClients,
  useClients
} from '../../hooks/clients/useClients'
import { LayoutList } from '../../layouts/List'

import { NotFound } from '../../components/molecules/NotFound'
import { useModal } from '../../hooks/useModal'

// debounce
let timer: any = null

export function Clients() {
  const { searchDefaultClient, setSearchDefaultClient } = useModal()
  const { clinicId } = useAuth()
  const [t] = useTranslation('pageClients')
  const isWideVersion = useBreakpointValue({
    base: false,
    lg: true
  })
  const skeletonRepeat = [1, 2, 3, 4, 5, 6, 7]
  const [currentPage, setCurrentPage] = useState(1)
  const [isActive, setIsActive] = useState(true)

  const getClientsData = useClients(
    currentPage,
    getClients(String(clinicId), currentPage, searchDefaultClient, isActive),
    searchDefaultClient,
    isActive
  )

  const SearchInputCallbackValue = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      const { value } = event.currentTarget

      clearTimeout(timer)

      timer = setTimeout(() => {
        setCurrentPage(1)
        setSearchDefaultClient(value)
      }, 500)
    },
    []
  )

  const ActiveCheckboxCallbackValue = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setIsActive(event.target.checked)
    },
    []
  )

  return (
    <LayoutList
      title={t('pageClients.tTitle.app')}
      urlNew="/clients/create"
      placeholder={t('placeholder.search')}
      refetch={getClientsData.refetch}
      defaultSearch={searchDefaultClient}
      capturaChange={(event: any) => SearchInputCallbackValue(event)}
      captureActiveChange={(event: any) => ActiveCheckboxCallbackValue(event)}
      ignorePermissions
    >
      <Box>
        <Table variant="striped" colorScheme="blue" size="sm">
          <Thead>
            <Tr>
              <Th>{t('pageClients.tName.app')}</Th>
              {isWideVersion ? (
                <>
                  <Th>{t('pageClients.tSocialName.app')}</Th>
                  <Th>{t('pageClients.tCelular.app')}</Th>
                  <Th>{t('pageClients.tDocumento.app')}</Th>
                  <Th>{t('pageClients.tCreateAt.app')}</Th>
                </>
              ) : null}

              <Th>{t('pageClients.tAcao.app')}</Th>
            </Tr>
          </Thead>
          <Tbody>
            {getClientsData.isLoading ? (
              <>
                {skeletonRepeat.map((skeleton) => (
                  <Tr>
                    <Td>
                      <Skeleton height="20px" />
                    </Td>
                    <Td>
                      <Skeleton height="20px" />
                    </Td>
                    <Td>
                      <Skeleton height="20px" />
                    </Td>
                    <Td>
                      <Skeleton height="20px" />
                    </Td>
                    <Td>
                      <Skeleton height="20px" />
                    </Td>
                    <Td>
                      <Skeleton height="20px" />
                    </Td>
                  </Tr>
                ))}
              </>
            ) : (
              <>
                {getClientsData.data?.clients.map(
                  (client: ClientsData, index: number) => (
                    <Tr key={client.id}>
                      <Td>
                        <Text fontSize="sm">{client?.name}</Text>
                      </Td>
                      {isWideVersion ? (
                        <>
                          <Td>
                            <Text fontSize="sm">{client?.social_name}</Text>
                          </Td>
                          <Td>
                            <Text fontSize="sm">{client?.telephone}</Text>
                          </Td>
                          <Td>
                            <Text fontSize="14px">
                              {client?.document_number}
                            </Text>
                          </Td>
                          <Td>
                            <Text fontSize="14px">
                              {format(
                                new Date(
                                  Number(client?.created_at?.substring(0, 4)),
                                  Number(client?.created_at?.substring(5, 7)) -
                                    1,
                                  Number(client?.created_at?.substring(8, 10))
                                ),
                                'dd/MM/yyyy'
                              )}
                            </Text>
                          </Td>
                        </>
                      ) : null}

                      <Td>
                        <Link
                          to={{
                            pathname: `/clients/update/${client.id}`,
                            state: client
                          }}
                        >
                          <Tooltip
                            label="Editar"
                            aria-label="editar"
                            hasArrow
                            placement="top-start"
                          >
                            <Box ml="1rem" w="24px">
                              <FiEdit
                                color="#9699B0"
                                size={16}
                                style={{ cursor: 'pointer' }}
                              />
                            </Box>
                          </Tooltip>
                        </Link>
                      </Td>
                    </Tr>
                  )
                )}
              </>
            )}
          </Tbody>
        </Table>
        {getClientsData.data?.totalCountOfPage === 0 &&
          !getClientsData.isLoading && <NotFound />}
      </Box>
      {getClientsData.data?.totalCountOfPage !== 0 &&
        !getClientsData.isLoading && (
          <Pagination
            totalCountOfRegisters={getClientsData.data?.totalCountOfPage || 0}
            currentPage={currentPage}
            onPageChange={setCurrentPage}
          />
        )}
    </LayoutList>
  )
}

/* eslint-disable camelcase */
import { useQuery } from 'react-query'
import { apiAuth } from '../../services/apiAuth'

export type DailyBox = {
  id: number
  status_payment: number
  ticket_number: string
  attendances: Array<{
    id: number
    guide: number
    specialist_name: string
    patient_name: string
  }>
  discount: number
  addition: number
  observation_financial: string
  observation_secretary: string
  total_value: number
  paid_value: number
  result_status: null | number
  generate_invoice: null | number
  created_at: string
  total_value_invoice: number | null
  items: [
    {
      id: number
      way_to_receive: {
        id: number
        name: string
        day_to_receive: number
        observation: string
        is_active: true
        way_to_receive_parcels: Array<{
          id: number
          parcel_number: number
          tax_percentage: number
          tax_absolute: number
          interest_rate_percentage: number
          interest_rate_absolule: number
          created_at: string
          updated_at: string
          is_active: boolean
          created_by: string
          updated_by: string
          way_to_receive: number
        }>
        active_parcels: 12
      }
      event_date: string
      value: number
      number_parcels: number
      parcel_value: number
      observation: null | string
      status_conferred: null | number
      date_status_conferred: string | null
    }
  ]
}

type WayData = {
  id: number
  name: string
  value: number
}

export type MetaData = {
  ways_to_receive: Array<WayData>
  total_value_way_to_receive: number
  tickets_total_value: number
  tickets_paid_value: number
}

export type ResponseGetDailyBox = {
  dailyBox: DailyBox[]
  meta: MetaData
  totalCountOfPage: number
}

export const getDailyBox = async (
  page: number,
  search: string = '',
  initialDate: string = '',
  finalDate: string = '',
  waysId: string[] | number[],
  statusConference: string[] | number[],
  statusPayment: string[] | number[],
  generateInvoice: string[] | number[],
  ticketNumber: string,
  patientName: string,
  patientId?: string
): Promise<ResponseGetDailyBox> => {
  try {
    const { data } = await apiAuth.get(`clinics/tickets/daily/`, {
      params: {
        page,
        keyword: search,
        startDate: initialDate,
        finishDate: finalDate,
        way_to_receive_id: waysId?.join(','),
        status_payment: statusPayment?.join(','),
        result_status: statusConference?.join(','),
        generate_invoice: generateInvoice?.join(','),
        ticket_number: ticketNumber,
        patient_name: patientName,
        patient_id: patientId
      }
    })

    const totalCountOfPage = data?.count || 0
    const dailyBox: DailyBox[] = data?.results.tickets || []
    const meta: MetaData = data?.results.meta || {}

    return {
      dailyBox,
      meta,
      totalCountOfPage
    }
  } catch (error: any) {
    console.log(error)

    const dailyBox: DailyBox[] = []
    const totalCountOfPage = 0
    const meta: MetaData = {
      ways_to_receive: [],
      tickets_paid_value: 0,
      tickets_total_value: 0,
      total_value_way_to_receive: 0
    }
    return {
      dailyBox,
      meta,
      totalCountOfPage
    }
  }
}

export function useDailyBox(
  page: number,
  functionLoading: Promise<ResponseGetDailyBox>,
  search: string = '',
  initialDate: string = '',
  finalDate: string = '',
  waysId: string[] | number[],
  statusConference: string[] | number[],
  statusPayment: string[] | number[],
  generateInvoice: string[] | number[],
  ticketNumber: string,
  patientName: string,
  patientId?: string,
  options?: {}
) {
  return useQuery(
    [
      'dailyBox',
      page,
      search,
      initialDate,
      finalDate,
      waysId,
      statusConference,
      statusPayment,
      generateInvoice,
      ticketNumber,
      patientName,
      patientId
    ],
    () => functionLoading,
    {
      staleTime: 1000 * 1, // 1 minute,
      cacheTime: 1000 * 60 * 15, // 15 minutes
      ...options
    }
  )
}

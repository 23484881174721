/* eslint-disable operator-assignment */
/* eslint-disable camelcase */
import {
  Box,
  Button,
  Center,
  CloseButton,
  Flex,
  HStack,
  SimpleGrid,
  Spinner,
  Stack,
  Table,
  Tbody,
  Td,
  Text,
  Textarea,
  Th,
  Thead,
  Tooltip,
  Tr,
  useBreakpointValue,
  useMediaQuery,
  useToast,
  VStack
} from '@chakra-ui/react'
import { format, addMonths } from 'date-fns'
import { FC, FormEvent, useEffect, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useHistory, useParams } from 'react-router-dom'
import { v4 as uuidV4 } from 'uuid'
import { Input } from '../../components/Form/Input'
import { SelectComponent } from '../../components/Form/SelectComponent'
import { TextFieldControlled } from '../../components/Form/TextFieldControlled'
import { CardContainer } from '../../components/molecules/CardContainer'
import { useAuth } from '../../hooks/auth'
import { useModal } from '../../hooks/useModal'
import { LayoutDefault } from '../../layouts/Default'
import { apiAuth } from '../../services/apiAuth'
import { formatBrOrDollarDefault } from '../../utils/fns/formatBrOrDollarDefault'
import { remaskCaractersAll } from '../../utils/fns/removeCaracters'

type FormData = {
  description: string
  date: string
}

type Budget = {
  id: number
  code: number
  due_date: string
  created_by: {
    id: number
    social_name: string
  }
  specialist: {
    id: number
    name: string
    social_name: string
  } | null
  patient: {
    id: number
    name: string
    social_name: string
  }
  created_at: string
  total: number
  items: [
    {
      id: number
      service: {
        id: number
        name: string
      }
      service_name: string
      sessions: number
      quantity: number
      unit_value: number
      discount_percent: number
      discount_absolute: number
      subtotal: number
    }
  ]
  discount_absolute: number
  discount_percent: number
  service: {
    id: number
    name: string
  }
}

type StateProps = {
  id: number | string
  name: string
  budget: Budget
}

type SpecialistsData = {
  id: number
  social_name: string
  user: {
    id: number
    name: string
    email: string
    contact: string
  }
}

interface Indication {
  id: number
  service: {
    id: number
    name: string
    observation: string
    is_active: boolean
    price: string
    days_to_come_back: number
    days_to_maturity: number
    protocol: string
    service_sessions: Array<{
      id: number
      duration: number
      time_to_be_there: number
      session_number: number
      service_id: number
    }>
    service_costs: unknown
    service_documents: unknown
    tag_service: {
      id: number
      name: string
      is_active: boolean
      observation: string
    }
  }
  service_name: string
  observation: string
  status: number
  indication_date: string | null
  specialist_name: string
  medical_record_finish_date: string
  updated_at: string
  updated_by_name: string | null
}

type Indications = Array<{
  label: string
  value: number
  idService: number
  nameService: string
  valueService: string
  sectionsService: number
}>

type ServicesData = {
  id: number
  name: string
  service_sessions: Array<{
    id: number
    duration: number
    time_to_be_there: number
    session_number: number
    service_id: number
  }>
  price: string
}

type BudgetsType = {
  id: string | number
  uuid: string
  name: string
  sections: number
  quantity: string
  value: string
  discountPercent: string
  discountMonetary: string
}

type ReceiveBudgets = {
  value: number
  nameService: string
  valueService: string
  sectionsService: number
}

type SelectedDefault = {
  value: number | string
  label: string
}

export const Budgets: FC = () => {
  const [t] = useTranslation('pageBudgets')
  const { LocaleApp } = useModal()
  const { clinicId, currency } = useAuth()
  const toast = useToast()
  const history = useHistory<StateProps>()
  const { state } = history.location
  const isWideVersion = useBreakpointValue({
    base: false,
    lg: true
  })
  const maxDate = addMonths(new Date(), 3)
  const { register, handleSubmit, formState } = useForm<FormData>({})
  const { errors } = formState
  // states values selects
  const [specialists, setSpecialists] = useState<SpecialistsData[]>([])
  const [indicationsOptions, setIndicationsOptions] = useState<Indications>([])
  const [services, setServices] = useState<ServicesData[]>([])
  // states selects
  const [specialistSelected, setSpecialistSelected] =
    useState<SelectedDefault | null>(null)
  const [serviceSelected, setServiceSelected] = useState<any>(null)
  const [indicationSelected, setIndicationSelected] = useState<any>(null)

  // states values table
  const [budgets, setBudgets] = useState<BudgetsType[]>([])
  // discount global states
  const [discountPercent, setDiscountPercent] = useState('')
  const [discountMonetary, setDiscountMonetary] = useState('')
  const [date, setDate] = useState(
    format(new Date(), 'yyyy-MM-dd', { locale: LocaleApp })
  )

  useEffect(() => {
    const getSpecialists = () => {
      apiAuth
        .get(`users/`, {
          params: {
            user__is_active: true,
            is_specialist: true
          }
        })
        .then((response) => {
          setSpecialists(response.data.results)
        })
    }

    const getIndications = () => {
      apiAuth
        .get(`clinics/patients/${state?.id}/indications/`, {
          params: {
            status: 1
          }
        })
        .then((response) => {
          if (response?.data?.count > 0) {
            const arrayOptionsFormatted: Indications =
              response?.data?.results?.map((ind: Indication) => {
                return {
                  label: `${format(
                    new Date(
                      Number(
                        ind?.indication_date?.substring(0, 4) ||
                          ind?.medical_record_finish_date?.substring(0, 4)
                      ),
                      Number(
                        ind?.indication_date?.substring(5, 7) ||
                          ind?.medical_record_finish_date?.substring(5, 7)
                      ) - 1,
                      Number(
                        ind?.indication_date?.substring(8, 10) ||
                          ind?.medical_record_finish_date?.substring(8, 10)
                      )
                    ),
                    'dd/MM/yyyy',
                    {
                      locale: LocaleApp
                    }
                  )} - ${ind?.specialist_name} - ${ind?.service_name}`,
                  value: ind?.service?.id,
                  idService: ind?.service?.id,
                  nameService: ind?.service_name,
                  valueService: formatBrOrDollarDefault(
                    String(Number(remaskCaractersAll(ind?.service?.price)))
                  ),
                  sectionsService: ind?.service?.service_sessions?.length
                }
              })

            setIndicationsOptions(arrayOptionsFormatted)
          } else {
            setIndicationsOptions([])
          }
        })
    }

    const getServices = () => {
      apiAuth
        .get(`clinics/${clinicId}/services/`, {
          params: {
            is_active: true,
            noPaginate: 1,
            onlyFields: 'id,name,service_sessions,price'
          }
        })
        .then((response) => {
          setServices(response.data)
        })
    }

    getSpecialists()
    getIndications()
    getServices()

    if (state?.budget) {
      if (state?.budget?.specialist) {
        setSpecialistSelected({
          value: state?.budget?.specialist?.id,
          label: state?.budget?.specialist?.social_name
        })
      }

      if (state?.budget?.items?.length > 0) {
        const recoverItens = state?.budget?.items?.map((b, index) => {
          return {
            id: state?.budget?.items[index].service?.id,
            uuid: String(b.id),
            name: b.service_name,
            sections: b.sessions,
            quantity: String(b.quantity),
            value: formatBrOrDollarDefault(b?.unit_value?.toFixed(2)),
            discountPercent: formatBrOrDollarDefault(
              b?.discount_percent?.toFixed(2)
            ),
            discountMonetary: formatBrOrDollarDefault(
              b?.discount_absolute?.toFixed(2)
            )
          }
        })

        setBudgets(recoverItens)
      }

      if (state?.budget?.discount_absolute) {
        setDiscountMonetary(
          formatBrOrDollarDefault(state?.budget?.discount_absolute?.toFixed(2))
        )
      }

      if (state?.budget?.discount_percent) {
        setDiscountPercent(
          formatBrOrDollarDefault(state?.budget?.discount_percent?.toFixed(2))
        )
      }
    }

    return () => {}
  }, [LocaleApp, state, clinicId])

  const handlerAddBudgetInServices = (budget: ReceiveBudgets) => {
    if (serviceSelected === null) {
      toast({
        title: 'Selecione ao menos 1 item de serviço',
        description: 'Não foi encontrado nenhum item selecionado para adição',
        duration: 3000,
        position: 'top-right',
        isClosable: true
      })

      return
    }

    setBudgets([
      ...budgets,
      {
        id: budget.value,
        name: budget?.nameService,
        uuid: uuidV4(),
        quantity: '1',
        sections: budget?.sectionsService,
        value: budget?.valueService,
        discountMonetary: '',
        discountPercent: ''
      }
    ])

    setServiceSelected(null)
  }

  const handlerAddBudgetInIndications = (budget: ReceiveBudgets) => {
    if (indicationSelected === null) {
      toast({
        title: 'Selecione ao menos 1 item de indicações',
        description: 'Não foi encontrado nenhum item selecionado para adição',
        duration: 3000,
        position: 'top-right',
        isClosable: true
      })

      return
    }

    setBudgets([
      ...budgets,
      {
        id: budget?.value,
        name: budget?.nameService,
        quantity: '1',
        uuid: uuidV4(),
        sections: budget?.sectionsService,
        value: budget?.valueService,
        discountMonetary: '',
        discountPercent: ''
      }
    ])

    setIndicationSelected(null)
  }

  // create budgets
  const handlerSubmitBudgets: SubmitHandler<FormData> = async (
    values,
    event
  ) => {
    event?.preventDefault()

    if (date === '') {
      toast({
        duration: 3000,
        status: 'error',
        title: `A data de validade é obrigatória`,
        position: 'top-right',
        description: `Selecione uma data`
      })

      return
    }

    if (budgets?.length === 0) {
      toast({
        duration: 3000,
        status: 'error',
        title: `Adicione pelo menos 1(um) item para gerar  orçamento`,
        position: 'top-right',
        description: `Não foram encontrados serviços para gerar orçamento.`
      })

      return
    }

    const payload = {
      patient: {
        id: Number(state?.id)
      },
      specialist:
        specialistSelected !== null
          ? {
              id: specialistSelected?.value
            }
          : null,
      due_date: date,
      discount_percent: Number(remaskCaractersAll(discountPercent)) / 100,
      discount_absolute: Number(remaskCaractersAll(discountMonetary)) / 100,
      items: budgets?.map((bud) => {
        return {
          service: { id: bud?.id },
          service_name: bud?.name,
          sessions: bud?.sections,
          quantity: Number(bud?.quantity || 1),
          unit_value: Number(remaskCaractersAll(bud?.value)) / 100,
          discount_percent:
            Number(remaskCaractersAll(bud?.discountPercent)) / 100,
          discount_absolute:
            Number(remaskCaractersAll(bud?.discountMonetary)) / 100
        }
      })
    }

    try {
      const { data } = await apiAuth.post(`clinics/quotes/`, payload)

      toast({
        duration: 3000,
        status: 'success',
        description: `${t('toastMessage.success')}`,
        position: 'top-right'
      })

      setTimeout(() => {
        history.push(`/clients/update/${state?.id}`)
      }, 2500)
    } catch (error: any) {
      toast({
        duration: 3000,
        status: 'error',
        title: `${error?.response?.data?.detail || t('toastMessage.error')}`,
        position: 'top-right',
        description: `${error?.data?.message}`
      })
    }
  }

  // return subtotal by line
  const returnSubTotalByLineIndex = (lineIndex: number) => {
    const value = Number(remaskCaractersAll(budgets[lineIndex].value)) / 100
    const quantity = Number(budgets[lineIndex].quantity)
    const discountPercentMulti =
      Number(remaskCaractersAll(budgets[lineIndex].discountPercent)) / 10000
    const discountMonetaryCalc =
      Number(remaskCaractersAll(budgets[lineIndex].discountMonetary)) / 100
    const discountPercentAmount = quantity * value * discountPercentMulti

    // logic arithmetic
    const calculateReturn =
      value * quantity - discountPercentAmount - discountMonetaryCalc

    return formatBrOrDollarDefault(calculateReturn.toFixed(2)) || '0'
  }

  // remove budgets
  const removeBudgetsByIndex = (position: number) => {
    const oldValues = [...budgets]

    oldValues.splice(position, 1)

    setBudgets(oldValues)
  }

  // change values in to array budgets
  const handlerChangeQuantity = (
    event: FormEvent<HTMLInputElement>,
    index: number
  ) => {
    event.preventDefault()

    const allBudgets = [...budgets]

    allBudgets[index].quantity = event.currentTarget.value

    setBudgets(allBudgets)
  }

  // change values in to array budgets
  const handlerChangeDiscountPercent = (
    event: FormEvent<HTMLInputElement>,
    index: number
  ) => {
    event.preventDefault()

    const allBudgets = [...budgets]

    allBudgets[index].discountPercent = event.currentTarget.value

    setBudgets(allBudgets)
  }

  // change values in to array budgets
  const handlerChangeDiscountMonetary = (
    event: FormEvent<HTMLInputElement>,
    index: number
  ) => {
    event.preventDefault()

    const allBudgets = [...budgets]

    allBudgets[index].discountMonetary = event.currentTarget.value

    setBudgets(allBudgets)
  }

  // return all acc subtotal
  const returnAccSubtotal = () => {
    let count = 0

    budgets.forEach((bud, index) => {
      const valueSubtotal =
        Number(remaskCaractersAll(returnSubTotalByLineIndex(index))) / 100

      count = count + valueSubtotal
    })

    return count
  }

  // return final value
  const returnFinalValue = () => {
    const balance = returnAccSubtotal()
    const discountCalcPercent =
      Number(remaskCaractersAll(discountPercent)) / 10000
    const discountCalcMonetary =
      Number(remaskCaractersAll(discountMonetary)) / 100

    const finalResult =
      balance - balance * discountCalcPercent - discountCalcMonetary

    return finalResult
  }

  return (
    <>
      <LayoutDefault
        title={
          isWideVersion ? `${t('title')} - ${state?.name}` : t('mobileTitle')
        }
        urlBack={`/clients/update/${state?.id}`}
        onSubmit={handleSubmit(handlerSubmitBudgets)}
      >
        <Stack w="100%" spacing="4" direction="column">
          <CardContainer title={t('container1.title')}>
            <VStack w="100%">
              <SelectComponent
                options={specialists?.map((specialist) => {
                  return {
                    value: specialist?.user?.id,
                    label: specialist?.social_name || specialist?.user?.name
                  }
                })}
                isClearable
                value={specialistSelected}
                onChange={(event: any) => setSpecialistSelected(event)}
                name="specialists"
                title={t('container1.label')}
                placeholder={t('container1.placeholder')}
              />
            </VStack>
          </CardContainer>

          <CardContainer title={t('container2.title')}>
            <VStack w="100%">
              <SelectComponent
                options={indicationsOptions}
                isClearable
                value={indicationSelected}
                onChange={(event: any) => setIndicationSelected(event)}
                name="indications"
                title={t('container2.label')}
                placeholder={t('container2.placeholder')}
              />
              <Flex
                w="100%"
                justifyContent="flex-end"
                alignItems="flex-end"
                mt="2"
              >
                <Button
                  size="sm"
                  bg="blue.300"
                  color="white"
                  onClick={() =>
                    handlerAddBudgetInIndications(indicationSelected)
                  }
                >
                  {t('btnAdd')}
                </Button>
              </Flex>
            </VStack>
          </CardContainer>
          <CardContainer title={t('container3.title')}>
            <VStack w="100%">
              <SelectComponent
                options={services.map((ser) => {
                  return {
                    value: ser?.id,
                    label: ser?.name,
                    nameService: ser?.name,
                    valueService: formatBrOrDollarDefault(
                      String(Number(remaskCaractersAll(ser?.price)))
                    ),
                    sectionsService: ser?.service_sessions?.length
                  }
                })}
                name="services"
                value={serviceSelected}
                onChange={(event: any) => setServiceSelected(event)}
                isClearable
                title={t('container3.label')}
                placeholder={t('container3.placeholder')}
              />
              <Flex
                w="100%"
                justifyContent="flex-end"
                alignItems="flex-end"
                mt="2"
              >
                <Button
                  size="sm"
                  bg="blue.300"
                  color="white"
                  onClick={() => handlerAddBudgetInServices(serviceSelected)}
                >
                  {t('btnAdd')}
                </Button>
              </Flex>
            </VStack>
          </CardContainer>
          <CardContainer title={t('container4.title')}>
            <VStack>
              <Table size="sm" variant="striped" colorScheme="blue">
                <Thead>
                  <Tr textAlign="initial">
                    <Th>{t('container4.table.t1')}</Th>
                    <Th>{t('container4.table.t2')}</Th>
                    <Th>{t('container4.table.t3')}</Th>
                    <Th>{t('container4.table.t4')}</Th>
                    <Th>{t('container4.table.t5')}</Th>
                    <Th>{t('container4.table.t6')}</Th>
                    <Th>{t('container4.table.t7')}</Th>
                    <Th>{t('container4.table.t8')}</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {budgets?.length > 0 &&
                    budgets?.map((bud, index) => (
                      <Tr key={bud?.id}>
                        <Td>
                          <Tooltip
                            aria-label={bud?.name}
                            label={bud?.name}
                            placement="right"
                          >
                            <Text
                              _hover={{ cursor: 'default' }}
                              fontSize="14px"
                              noOfLines={1}
                            >
                              {bud?.name}
                            </Text>
                          </Tooltip>
                        </Td>
                        <Td>
                          <Text
                            _hover={{ cursor: 'default' }}
                            fontSize="14px"
                            noOfLines={1}
                          >
                            {bud?.sections}
                          </Text>
                        </Td>
                        <Td>
                          <Box w="100px">
                            <Input
                              name="quantity"
                              type="number"
                              min={1}
                              max={999}
                              value={bud?.quantity}
                              onChange={(event) =>
                                handlerChangeQuantity(event, index)
                              }
                            />
                          </Box>
                        </Td>
                        <Td textAlign="right">
                          <Text
                            _hover={{ cursor: 'default' }}
                            fontSize="14px"
                            noOfLines={1}
                          >
                            {bud.value}
                          </Text>
                        </Td>
                        <Td>
                          <Box w="100px">
                            <TextFieldControlled
                              name="discountPercent"
                              inputOnChange={(e) =>
                                handlerChangeDiscountPercent(e, index)
                              }
                              initialValue={bud?.discountPercent}
                              mask={
                                currency === 'BRL'
                                  ? 'monetary'
                                  : 'monetaryDollar'
                              }
                            />
                          </Box>
                        </Td>
                        <Td>
                          <TextFieldControlled
                            name="discountMonetary"
                            initialValue={bud?.discountMonetary}
                            inputOnChange={(e) =>
                              handlerChangeDiscountMonetary(e, index)
                            }
                            mask={
                              currency === 'BRL' ? 'monetary' : 'monetaryDollar'
                            }
                          />
                        </Td>
                        <Td>{returnSubTotalByLineIndex(index)}</Td>
                        <Td>
                          <CloseButton
                            onClick={() => {
                              removeBudgetsByIndex(index)
                            }}
                            _hover={{ bg: 'red.500', color: 'white' }}
                          />
                        </Td>
                      </Tr>
                    ))}
                </Tbody>
              </Table>
              {budgets.length === 0 && (
                <Center mt="2" w="100%">
                  <Text fontSize="14px" fontWeight="hairline">
                    {t('noAddBudgets')}
                  </Text>
                </Center>
              )}
            </VStack>
          </CardContainer>

          <CardContainer title={t('container5.title')}>
            <VStack mt="4" w="100%" alignItems="flex-start">
              {budgets.length > 0 && (
                <HStack w="100%" justifyContent="space-between" px="4">
                  <Box w="180px">
                    <Input
                      {...register('date')}
                      type="date"
                      min={format(new Date(), 'yyyy-MM-dd', {
                        locale: LocaleApp
                      })}
                      max={format(maxDate, 'yyyy-MM-dd', {
                        locale: LocaleApp
                      })}
                      isRequired
                      value={date}
                      onChange={(e) => setDate(e.target.value)}
                      label="Data de validade"
                    />
                  </Box>
                  <HStack>
                    <Input
                      name="someSubtotals"
                      label="Valor total"
                      value={formatBrOrDollarDefault(
                        returnAccSubtotal().toFixed(2)
                      )}
                      textAlign="right"
                      isDisabled
                    />
                    <Box w="400px">
                      <TextFieldControlled
                        name="discountPercentGlobal"
                        label={t('container4.table.t5')}
                        inputOnChange={(e) =>
                          setDiscountPercent(e.currentTarget.value)
                        }
                        initialValue={discountPercent}
                        mask={
                          currency === 'BRL' ? 'monetary' : 'monetaryDollar'
                        }
                      />
                    </Box>
                    <TextFieldControlled
                      name="discountMonetaryGlobal"
                      label={t('container4.table.t6')}
                      inputOnChange={(e) =>
                        setDiscountMonetary(e.currentTarget.value)
                      }
                      initialValue={discountMonetary}
                      mask={currency === 'BRL' ? 'monetary' : 'monetaryDollar'}
                    />
                    <Input
                      name="finalValue"
                      label="Valor final"
                      value={formatBrOrDollarDefault(
                        returnFinalValue().toFixed(2)
                      )}
                      textAlign="right"
                      isDisabled
                    />
                  </HStack>
                </HStack>
              )}
              <Text fontSize="14px">{t('container5.observation')}</Text>
              <Textarea
                placeholder={t('container5.observationPlaceholder')}
                {...register('description')}
              />
            </VStack>
          </CardContainer>

          <Flex w="100%" alignItems="center" justifyContent="flex-end">
            <Button
              type="submit"
              size="md"
              fontSize="md"
              bg="blue.300"
              color="white"
              isLoading={formState.isSubmitting}
            >
              {t('btnUpdate')}
            </Button>
          </Flex>
          <Flex w="100%" h="300px" />
        </Stack>
      </LayoutDefault>
    </>
  )
}

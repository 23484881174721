/* eslint-disable camelcase */
import { useQuery } from 'react-query'
import { apiAuth } from '../../services/apiAuth'

export type ServicesData = {
  name: string
  price: string
  id: string
  service_sessions: Array<{}>
  days_to_come_back: number
  days_to_maturity: number
  tag_service: {
    id: number
    name: string
  }
}

export type ResponseGetServices = {
  services: ServicesData[]
  totalCountOfPage: number
}

export const getServices = async (
  clinicsId: string = '',
  page: number,
  search: string = '',
  isActive: boolean = true,
  groups: any
): Promise<ResponseGetServices> => {
  try {
    const { data } = await apiAuth.get(`clinics/services/`, {
      params: {
        page,
        keyword: search,
        is_active: isActive,
        ordering: 'name',
        groups: groups.join(',')
      }
    })

    const totalCountOfPage = data.count
    const services: ServicesData[] = data.results

    return {
      services,
      totalCountOfPage
    }
  } catch (error: any) {
    console.log(error)

    const services: ServicesData[] = []
    const totalCountOfPage = 0
    return {
      services,
      totalCountOfPage
    }
  }
}

export function useServices(
  page: number,
  functionLoading: Promise<ResponseGetServices>,
  search: string,
  isActive: boolean,
  groups: Array<String> | String,
  options?: {}
) {
  return useQuery(
    ['services', page, search, isActive, groups],
    () => functionLoading,
    {
      staleTime: 1000 * 60 * 5, // 5 minutes
      cacheTime: 1000 * 60 * 5, // 5 minutes
      ...options
    }
  )
}
